@charset "UTF-8";
* { margin: 0px; padding: 0px; }

html { font-size: 62.5%; height: 100%; }

body { width: 100%; overflow-x: hidden; font-family: Verdana, Geneva, Tahoma, sans-serif; font-size: 14px; font-size: 1.4rem; color: black; }

a { color: #333; text-decoration: none; outline: none; tap-highlight-color: transparent; focus-ring-color: transparent; -webkit-tap-highlight-color: transparent; -webkit-focus-ring-color: transparent; -moz-tap-highlight-color: transparent; -moz-focus-ring-color: transparent; transition: all 350ms; }

a:hover { color: #0087d3; }

ol, ul, li { list-style: none; }

img { max-width: 100%; border: 0px solid #ccc; }

table, embed, video { max-width: 100%; }

input, textarea { border: none; background: none; font-family: inherit; }

input[type="submit"], textarea[type="submit"] { cursor: pointer; }

input[type="checkbox"], textarea[type="checkbox"] { cursor: pointer; }

input[type="radio"], textarea[type="radio"] { cursor: pointer; }

input:focus, textarea:focus { outline: none; }

textarea { resize: none; }

button { border: none; background: none; cursor: pointer; }

em, i { font-style: normal; }

/* .c-xx start */
.c-fl { float: left; }

.c-fr { float: right; }

.c-cb { clear: both; }

.c-mg { margin-top: 0; margin-right: auto; margin-bottom: 0; margin-left: auto; }

.c-oh { overflow: hidden; }

.c-wd { width: 1200px; }

.c-tsn { transition: all 350ms; }

.c-bbx { box-sizing: border-box; }

.c-pr { position: relative; }

.c-mt-5 { margin-top: 5px; }

.c-mt-10 { margin-top: 10px; }

.c-mt-15 { margin-top: 15px; }

.c-mt-20 { margin-top: 20px; }

.c-mt-25 { margin-top: 25px; }

.c-mt-30 { margin-top: 30px; }

.c-mt-35 { margin-top: 35px; }

.c-mt-40 { margin-top: 40px; }

.c-mt-45 { margin-top: 45px; }

.c-mt-50 { margin-top: 50px; }

.c-nomt { margin-top: 0 !important; }

.c-nomr { margin-right: 0 !important; }

.c-nomb { margin-bottom: 0 !important; }

.c-noml { margin-left: 0 !important; }

.c-nofont { line-height: 0; font-size: 0; }

.c-nobg { background: none !important; }

/* .c-xx end */
/* .f-xx start */
.f-czjz { position: relative; top: 50%; transform: translate(0px, -50%); }

.f-box { margin: 0 auto; max-width: 1200px; overflow: hidden; }

@media screen and (min-width: 960px) and (max-width: 1219px) { .f-box { width: 950px; } }

@media screen and (min-width: 768px) and (max-width: 959px) { .f-box { width: 750px; } }

@media screen and (max-width: 767px) { .f-box { padding-left: 10px; padding-right: 10px; box-sizing: border-box; } }

/* .f-xx end */
.l { float: left; }

.r { float: right; }

.fix { *zoom: 1; }

.fix:after, .fix:before { display: block; content: "clear"; height: 0; clear: both; overflow: hidden; visibility: hidden; }

.box { position: relative; }

.mln { margin-left: 0 !important; }

.clear { clear: both; }

.wp { width: 1200px; margin: auto; }

.wpp { width: 1400px; margin: auto; }

@media screen and (min-width: 1000px) and (max-width: 1419px) { .wpp { width: 980px; margin: auto; } }

@media screen and (min-width: 1000px) and (max-width: 1219px) { .wp { width: 980px; margin: auto; } }

@media screen and (max-width: 999px) { .wp { width: 95%; margin: auto; }
  .wpp { width: 95%; margin: auto; } }

/* .m-xx start */
.m-desc { font-size: 14px; font-size: 1.4rem; color: #333; line-height: 30px; }

.m-desc a { color: #0087d3; }

.m-desc a:hover { text-decoration: underline; }

.m-desc p { font-size: 14px; font-size: 1.4rem; color: #333; line-height: 30px; }

.m-desc span { font-size: 14px; font-size: 1.4rem; color: #333; line-height: 30px; }

.m-desc b { font-size: 22px; font-size: 2.2rem; color: #333; line-height: 30px; }

.m-desc a { font-size: 14px; font-size: 1.4rem; color: #0087d3; line-height: 30px; }

.m-desc img { display: block; max-width: 100%; margin: 0 auto; }

.m-desc iframe { display: block; max-width: 100%; margin: 0 auto; }

.m-desc table { width: 90%; border-collapse: collapse; line-height: 22px; border: 1px solid #ccc; margin-left: auto; margin-right: auto; }

.m-desc table tr td { padding: 3px; border: 1px solid #ccc; background: #fff; font-size: 14px; font-size: 1.4rem; }

@media screen and (max-width: 767px) { .m-tt1 { font-size: 24px !important; font-size: 2.4rem !important; }
  .m-tt2 { font-size: 20px !important; font-size: 2rem !important; }
  .m-tt3 { font-size: 18px !important; font-size: 1.8rem !important; }
  .m-desc1 { font-size: 16px !important; font-size: 1.6rem !important; }
  .m-desc2 { font-size: 14px !important; font-size: 1.4rem !important; }
  [class*="-item"] .item-title { font-size: 14px; font-size: 1.4rem; }
  [class*="-item"] .item-desc { font-size: 13px; font-size: 1.3rem; }
  [class*="-item"] .item-link { font-size: 13px; font-size: 1.3rem; }
  [class*="-item"] .item-modal { display: none; } }

/* .m-xx end */
/* .j-xx start */
.j-offset-top { transition: all 750ms; transform: translateY(100px); opacity: 0; }

.j-offset-top.run { transform: translateY(0); opacity: 1; }

.j-offset-left { transition: all 750ms; transform: translateX(100px); opacity: 0; }

.j-offset-left.run { transform: translateX(0); opacity: 1; }

.j-offset-right { transition: all 750ms; transform: translateX(-100px); opacity: 0; }

.j-offset-right.run { transform: translateX(0); opacity: 1; }

/* .j-xx end */
[class*="-item"] .item-pic { position: relative; display: block; line-height: 0; font-size: 0; text-align: center; overflow: hidden; }

[class*="-item"] .item-pic img { transition: all 350ms; }

[class*="-item"] .item-pic:hover img { transform: scale(1.1); }

[class*="-item"] .item-title { overflow: hidden; }

[class*="-item"] .item-title a:hover { color: #0087d3; }

[class*="-item"] .item-desc { overflow: hidden; }

[class*="-item"] .item-desc a { display: block; }

[class*="-item"] .item-desc a:hover { color: #0087d3; }

.m-crm { margin-top: 18px; line-height: 30px; font-size: 14px; font-size: 1.4rem; color: #333; margin-bottom: 40px; }

.m-crm .wp { padding-left: 25px; background: url(../images/crm.png) no-repeat left 10px; border-bottom: 1px solid #cccccc; padding-bottom: 4px; box-sizing: border-box; }

.m-crm a { color: #333; }

.m-crm a:hover { color: #0087d3; }

.m-crm a.active { color: #0087d3; }

@media screen and (max-width: 767px) { .m-crm { margin-bottom: 20px; } }

.m-page { letter-spacing: 0; font-size: 0; text-align: center; }

.m-page a { margin: 0 5px; display: inline-block; *display: inline; *zoom: 1; width: 35px; height: 30px; line-height: 28px; font-size: 14px; font-size: 1.4rem; color: #333; text-align: center; border: 1px solid #e5e5e5; box-sizing: border-box; transition: all 350ms; }

.m-page a.big { width: 75px; }

.m-page a:hover { color: white; border-color: #0087d3; background-color: #0087d3; }

.m-page a.active { color: white; border-color: #0087d3; background-color: #0087d3; }

.m-page span { display: inline-block; width: 50px; text-align: center; font-size: 14px; font-size: 1.4rem; color: #4b4b4b; position: relative; top: 3px; }

.m-page img { display: inline-block; position: relative; top: 5px; margin-left: 60px; margin-right: 8px; }

.m-page .ins { margin-left: 10px; width: 170px; display: inline-block; height: 32px; line-height: 30px; text-align: center; border: none; outline: none; background-color: #0087d3; color: #fff; font-size: 14px; font-size: 1.4rem; cursor: pointer; }

@media screen and (max-width: 1219px) { .m-page img { margin-left: 10px; }
  .m-page .ins { width: 140px; } }

@media screen and (max-width: 767px) { .m-page img, .m-page span { display: none; } }

.m-page2 { position: relative; }

@media screen and (max-width: 767px) { .m-page { margin-top: 25px; display: flex; flex-flow: row wrap; justify-content: center; }
  .m-page a { margin-left: 5px; margin-right: 5px; margin-bottom: 10px; } }

/**********************  ce  ************************/
.ce { position: fixed; top: 20%; z-index: 99999; width: 69px; background: #f8f8f8; right: 0px; padding: 10px 0px; border-radius: 50px; }

.ce li { margin: 5px 10px; border-bottom: 1px solid #aaaaaa; padding-bottom: 5px; text-align: center; }

.ce li.noborder { border: none; }

.ce li span { display: block; margin-top: 1px; font-size: 12px; font-size: 1.2rem; }

.ce li img { margin-top: 6px; }

.ce li:hover span { color: #0087d3; }

.weixin { position: relative; }

.ewm { position: absolute; right: 100%; bottom: -99999px; max-width: 100px; max-height: 100px; }

.weixin:hover .ewm { bottom: 0; }

@media screen and (max-width: 767px) { .ce { bottom: 0; top: auto; width: 100%; padding: 0; border-radius: 0; right: 0; }
  .ce li { border: none; float: left; width: 25%; margin: 0; }
  body { padding-bottom: 60px; }
  .ewm { right: auto; left: 0; }
  .weixin:hover .ewm { bottom: 100%; }
  .ce li.noborder img { margin-top: 13px; } }

.returntop { position: fixed; *position: absolute; z-index: 9; right: 6px; bottom: 30px; width: 72px; height: 72px; background: url("../images/gotop.png") no-repeat center; line-height: 0; font-size: 0; display: none; }

@media screen and (max-width: 959px) { .returntop { right: 0; bottom: 65px; width: 36px; height: 36px; background-size: contain; } }

.wap-hd-nav { display: none; }

.wap-nav { width: 5px; height: 100%; position: absolute; right: 0; -webkit-transition: all 0.4s ease 0s; transition: all 0.4s ease 0s; z-index: 999999999999999; cursor: pointer; position: fixed; top: -5px; right: 0; display: block; display: none\0; }

.wap-nav .nav { overflow: hidden; width: 300px; background: rgba(0, 0, 0, 0.8); position: absolute; top: 0; right: 0; display: block; height: auto; overflow: scroll; }

.wap-nav .nav span { font-family: arial, helvetica, sans-serif; }

.wap-nav .nav form { margin: 15px auto; width: 258px; height: 26px; border: 1px solid #fff; }

.wap-nav .nav form .iptc { box-sizing: border-box; padding-left: 15px; background-color: #fff; width: 80%; height: 26px; line-height: 26px; display: block; float: left; }

.wap-nav .nav form .ipsc { width: 20%; height: 26px; line-height: 26px; display: block; float: right; background: #fff url("../images/sch2.png") no-repeat center center; }

.wap-nav .nav > ul { display: none; margin-top: 0px; margin-bottom: 0px; overflow: scroll; padding-top: 45px; -ms-box-sizing: border-box; -o-box-sizing: border-box; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.wap-nav .nav > ul > li { line-height: 40px; font-size: 12px; font-size: 1.2rem; text-indent: 20px; position: relative; }

.wap-nav .nav > ul > li .addition { width: 20px; height: 20px; position: absolute; right: 15px; top: 10px; background: url("../images/shizi.png") no-repeat center center; }

.wap-nav .nav > ul > li .addition.fa:before { margin-right: 5px; color: #fff; text-indent: 0; }

.wap-nav .nav > ul > li > a { margin-right: 40px; color: #fff; font-size: 12px; font-size: 1.2rem; display: block; }

.wap-nav .nav > ul > li > a span { color: #fff; font-size: 12px; font-size: 1.2rem; }

.wap-nav .nav > ul > li .c-show { display: none; width: 100%; }

.wap-nav .nav > ul > li .c-show1 .c-s1 { position: relative; padding-left: 15px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > a { position: relative; margin-right: 40px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > a span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 { position: relative; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 { position: relative; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 > a { margin-right: 40px; text-indent: 35px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 > a span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.wap-nav .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 { position: relative; }

.wap-nav .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a { margin-right: 40px; text-indent: 50px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.wap-nav .nav > ul > dl { padding: 20px; }

.wap-nav .nav > ul > dl dd { float: left; margin: 0 2px; }

.wap-nav .menubtn { width: 23px; height: 18px; position: absolute; top: 12px; right: 12px; -webkit-transition: all 0.4s ease 0s; transition: all 0.4s ease 0s; z-index: 9999; display: none\0; }

.wap-nav .menubtn span { display: block; width: 100%; height: 3px; margin-bottom: 3px; background-color: #0087d3; }

@media screen and (min-width: 960px) { .wap-nav, .menubtn { display: none; } }

@media screen and (max-width: 959px) { .wap-hd-nav { display: flex; flex-flow: row nowrap; justify-content: space-between; background-color: #0087d3; }
  .wap-hd-nav-item { width: 25%; }
  .wap-hd-nav-item a { display: block; padding: 5px 0; line-height: 20px; font-size: 14px; color: white; text-align: center; background-color: #363636; }
  .wap-hd-nav-item a.active { color: white; background-color: #0087d3; } }

/* header_top */
.header_top { margin-bottom: 15px; text-align: right; margin-top: 12px; }

.header_top a { display: inline-block; line-height: 30px; color: #666666; }

.header_top span { display: inline-block; line-height: 30px; padding-left: 25px; color: #666666; margin-top: 2px; }

.header_top .header_form { margin-top: 5px; border: 1px solid #333333; width: 209px; height: 22px; line-height: 22px; border-radius: 50px; position: relative; margin-left: 28px; }

.header_top .header_form .tex { background: transparent; border-radius: 50px; text-indent: 10px; width: 100%; height: 22px; line-height: 22px; display: inline-block; color: #999999; font-style: italic; font-size: 14px; font-size: 1.4rem; }

.header_top .header_form .sub { position: absolute; top: 0px; right: 0px; height: 34px; width: 30px; font-size: 0px; cursor: pointer; z-index: 99; background: url(../images/suosou.png) no-repeat left -1px; }

.header_top .shejiao a { margin-left: 16px; }

/* header */
.header { background: url(../images/header.png) no-repeat center top; }

.header .logo { margin-top: 0px; }

.header .logo img { display: block; max-width: 200px; max-height: 177px; width: 100%; margin: 0 auto; }

.header_ul1 > li { float: left; margin-left: 35px; margin-right: 0px; position: relative; }

.header_ul1 > li:hover .dan_header { display: block; }

.header_ul1 > li > a { color: #333; line-height: 38px; display: block; font-weight: bold; text-transform: uppercase; padding-bottom: 15px; }

.header_ul1 > li:hover > a, .header_ul1 li.on > a { color: #0087d3; background: url(../images/hewli.png) no-repeat center bottom; }

.dan_header { display: none; width: 171px; position: absolute; left: 0px; top: 100%; background: #fff; z-index: 999999; }

.dan_header > ul { border: 1px solid #cccccc; }

.dan_header > ul > li { line-height: 36px; position: relative; left: 0px; }

.dan_header > ul > li > a { padding-left: 37px; position: relative; font-size: 14px; font-size: 1.4rem; color: #666666; line-height: 36px; display: block; }

.dan_header > ul > li:hover > a { color: #0087d3; background: url(../images/hewli2.png) no-repeat 11px 15px; }

.dan_header > ul > li:hover .dan_sanheader { display: block; }

.dan_sanheader { display: none; width: 297px; position: absolute; left: 100%; top: 0px; z-index: 999999; border-left: 2px solid transparent; }

.dan_sanheader > ul { border: 1px solid #cccccc; background: #fff; }

.dan_sanheader > ul > li { line-height: 36px; position: relative; left: 0px; }

.dan_sanheader > ul > li > a { padding-left: 37px; position: relative; font-size: 14px; font-size: 1.4rem; color: #666666; line-height: 36px; display: block; }

.dan_sanheader > ul > li:hover > a { color: #0087d3; background: url(../images/hewli2.png) no-repeat 11px 15px; }

.dan_sanheader > ul > li:hover .dan_sanheader2 { display: block; }

.dan_sanheader2 { display: none; width: 120px; position: absolute; left: 100%; top: 0px; z-index: 999999; border-left: 2px solid transparent; }

.dan_sanheader2 > ul { border: 1px solid #cccccc; background: #fff; }

.dan_sanheader2 > ul > li { line-height: 36px; position: relative; left: 0px; }

.dan_sanheader2 > ul > li > a { padding-left: 37px; position: relative; font-size: 14px; font-size: 1.4rem; color: #666666; line-height: 36px; display: block; }

.dan_sanheader2 > ul > li:hover > a { color: #0087d3; }

@media screen and (max-width: 1419px) { .header_ul1 > li { margin-left: 20px; }
  .header .logo { width: 100px; }
  .header_top .header_form { width: 130px; } }

@media screen and (max-width: 1219px) { .header_ul1 > li { margin-left: 10px; } }

@media screen and (max-width: 999px) { .header { position: relative; }
  .header_ul1 > li > a { font-size: 14px; font-size: 1.4rem; }
  .header_top span { font-size: 12px; font-size: 1.2rem; } }

@media screen and (max-width: 959px) { .header { background: none; }
  .header_top .header_form { display: none; }
  .header_top { display: none; }
  .header_ul1 { display: none; }
  .header .logo { float: none; margin: 0 auto; width: 100%; max-width: 200px; margin-top: 10px; margin-bottom: 10px; } }

@media screen and (max-width: 768px) { .slide .carousel-control { display: none; } }

/* footer */
.footer { background: #ececec; padding-top: 45px; border-top: 1px solid #d7d7d7; padding-bottom: 37px; }

.footer a:hover { color: #0087d3; }

.footer .title { font-size: 24px; font-size: 2.4rem; color: #333; line-height: 30px; text-transform: uppercase; font-weight: bold; }

.footer .title a { color: #333; }

.footer_left { float: left; width: 322px; }

.footer_center { float: left; width: 271px; }

.footer_right { float: right; width: 494px; }

.ftx_ul { margin-top: 22px; }

.ftx_ul li { line-height: 36px; color: #333333; padding-left: 30px; }

.ftx_ul li a { color: #333333; }

.ftx_ul li.li1 { background: url(../images/foli1.png) no-repeat left 10px; }

.ftx_ul li.li2 { background: url(../images/foli2.png) no-repeat left 10px; }

.ftx_ul li.li3 { background: url(../images/foli3.png) no-repeat left 10px; }

.ftx_ul li.li4 { background: url(../images/foli4.png) no-repeat left 10px; }

.ftx_ul li.li5 { background: url(../images/foli5.png) no-repeat left 10px; }

.ftx_ul li.li6 { background: url(../images/foli6.png) no-repeat left 10px; }

.ftx_ul2 { margin-top: 22px; }

.ftx_ul2 li { line-height: 36px; color: #333333; }

.ftx_ul2 li a { padding-left: 20px; background: url(../images/fibgj.png) no-repeat left 4px; color: #333333; }

.footer_right .title { padding-left: 20px; }

.footer_right form { margin-top: 34px; }

.footer_right .c_div { margin-top: 0px; margin-bottom: 10px; }

.footer_right .c_div2 { margin-top: 10px; }

.footer_right .c_nr { width: 224px; padding-left: 20px; }

.footer_right .c_nr2 { padding-left: 20px; }

.footer_right .xing { background: url(../images/xing.png) no-repeat left 19px; }

.footer_right .c_div_l { padding-left: 20px; background: url(../images/xing.png) no-repeat left 19px; }

.footer_right .c_input, .footer_right .c_captcha { font-size: 14px; font-size: 1.4rem; color: #999999; height: 42px; line-height: 42px; border: 1px solid #cccccc; }

.footer_right .c-span { width: 18px; display: block; height: 42px; line-height: 42px; font-size: 14px; font-size: 1.4rem; color: #999999; text-indent: 1px; }

.footer_right .c_input { width: 190px; padding-left: 35px; display: block; background-color: #fff; }

.footer_right .c_textarea { font-size: 14px; font-size: 1.4rem; color: #999999; font-family: Arial; line-height: 42px; border: 1px solid #cccccc; background: #fff url(../images/xp-icon5.png) no-repeat left top; padding-left: 35px; width: 93%; height: 124px; }

.footer_right .c_yzm { display: block; float: left; cursor: pointer; }

.footer_right .c_captcha { display: block; float: left; padding-left: 15px; width: 155px; margin-right: 11px; background: #fff; }

.footer_right .c_send { float: right; width: 161px; background: #0087d3; color: #fff; height: 42px; text-transform: uppercase; line-height: 36px; cursor: pointer; transition: 0.5s; -moz-transition: 0.5s; -o-transition: 0.5s; -webkit-transition: 0.5s; }

.footer_right .c_send:hover { background: #aaa; }

.footer_right .c_xpbg1 .c_input { display: block; background: #fff url(../images/xp-icon1.png) no-repeat left top; }

.footer_right .c_xpbg2 .c_input { display: block; background: #fff url(../images/xp-icon2.png) no-repeat left top; }

.shejiao a img { -webkit-transition: 0.3s all; -moz-transition: 0.3s all; -ms-transition: 0.3s all; transition: 0.3s all; }

.shejiao a:hover img { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -ms-transform: rotate(360deg); transform: rotate(360deg); }

.footer_bot { padding-top: 35px; border-top: 1px solid #d7d7d7; color: #333; text-align: center; background: #f8f7f7; padding-bottom: 20px; }

.footer_bot a:hover { color: #0087d3; }

.footer_bot .shejiao { display: inline-block; }

.footer_bot .shejiao a { display: block; margin-left: 11px; float: left; margin-bottom: 22px; }

.footer_bot p { color: #333; line-height: 30px; }

.footer_bot p a { display: inline-block; padding-left: 2px; line-height: 30px; color: #333; }

.footer_bot .Links { height: 30px; line-height: 30px; color: #333; }

.footer_bot .Links a { display: inline-block; padding-right: 25px; color: #333; }

@media screen and (max-width: 1419px) { .footer_left { width: 49%; margin: 0 auto; }
  .footer_center { width: 49%; margin: 0 auto; }
  .footer_right { float: right; width: 49%; margin: 0 auto; }
  .footer .title { margin-top: 20px; }
  .footer_right .c_div { margin-bottom: 0px; }
  .footer_right .c_nr { width: calc( 100% - 20px); }
  .footer_right .c_input { width: calc( 100% - 35px); margin-bottom: 20px; }
  .footer_right .c_textarea { width: calc( 100% - 35px); margin-bottom: 20px; }
  .footer_right .c_captcha { width: calc( 100% - 15px); margin-bottom: 20px; } }

@media screen and (max-width: 999px) { .footer_left { width: 33.33%; float: left; box-sizing: border-box; }
  .footer_center { width: 33.33%; float: left; margin-left: 0px; box-sizing: border-box; }
  .footer_right { width: 100%; box-sizing: border-box; margin-top: 20px; }
  .ftx_ul li { font-size: 12px; font-size: 1.2rem; }
  .ftx_ul2 li { font-size: 12px; font-size: 1.2rem; } }

@media screen and (max-width: 768px) { .footer { display: none; }
  .footer_left, .footer_right, .footer_center { display: none; }
  .Links { display: none; }
  .footer_bot .shejiao a { margin-left: 4px; }
  .footer_bot { border: 0px; }
  .footer_bot p { float: none; }
  .footer .shejiao { float: none; margin: 0 auto; }
  .footer .shejiao a { margin-left: 0px; margin-top: 10px; margin-right: 20px; } }

.main_title { padding-top: 59px; text-align: center; }

.main_title a { display: inline-block; padding: 0px 154px; background: url(../images/main_tit.png) no-repeat left center, url(../images/main_tit.png) no-repeat right center; font-size: 40px; font-size: 4rem; font-weight: bold; text-transform: uppercase; }

.main_title span { color: #0087d3; }

@media screen and (max-width: 999px) { .main_title a { font-size: 30px; font-size: 3rem; } }

@media screen and (max-width: 768px) { .main_title { padding-top: 40px; }
  .main_title a { padding: 0px; background: none; font-size: 28px; font-size: 2.8rem; } }

.main_p { max-width: 961px; margin: 0 auto; text-align: center; margin-top: 34px; }

.main_p a { display: block; line-height: 30px; max-height: 60px; overflow: hidden; }

@media screen and (max-width: 768px) { .main_p { margin-top: 10px; } }

/*==========main1==================*/
.main1 { background: url(../images/main1.png) no-repeat center top; padding-bottom: 95px; }

.main1 .img-scroll { position: relative; width: 100%; margin-top: 40px; }

.main1 .img-scroll .prev { position: absolute; display: block; width: 18px; height: 32px; top: 50%; margin-top: -32px; z-index: 9; }

.main1 .img-scroll .next { position: absolute; display: block; width: 18px; height: 32px; top: 50%; margin-top: -32px; z-index: 9; }

.main1 .img-scroll .prev { left: 0; cursor: pointer; background: url(../images/arrowleft.png) no-repeat center; }

.main1 .img-scroll .next { right: 0; cursor: pointer; background: url(../images/arrowright.png) no-repeat center; }

.main1 .img-scroll .prev:hover { background: url(../images/arrowlefton.png) no-repeat center; }

.main1 .img-scroll .next:hover { background: url(../images/arrowrighton.png) no-repeat center; }

.main1 .img-list { position: relative; width: 93%; overflow: hidden; margin: 0 auto; }

.main1 .img-list ul { width: 1000%; }

.main1 .img-list li { float: left; width: 254px; margin-right: 33px; }

.main1 .img-list li img { display: block; max-width: 254px; max-height: 233px; width: 100%; margin: 0 auto; border: 1px solid #cccccc; box-sizing: border-box; }

.main1 .img-list li .m4zi { display: block; margin: 0 auto; max-width: 254px; width: 100%; font-size: 16px; font-size: 1.6rem; margin-top: 10px; color: #333; line-height: 38px; text-align: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

.main1 .img-list li .m3lien { max-width: 254px; text-align: center; position: relative; margin: 0 auto; overflow: hidden; }

@media screen and (max-width: 1219px) { .main1 .img-list { width: 89%; }
  .main1 .img-list li { width: 192px; } }

@media screen and (max-width: 999px) { .main1 .img-list li { width: 3%; margin-right: .5%; } }

@media screen and (max-width: 768px) { .main1 { padding-bottom: 50px; }
  .main1 .img-scroll { margin-top: 20px; }
  .main1 .img-list li { width: 4.5%; margin-right: 1%; }
  .main1 .img-list li .m4zi { font-size: 14px; font-size: 1.4rem; } }

@media screen and (max-width: 400px) { .main1 .img-list li { width: 10%; margin-right: .487%; } }

/*==========main2==================*/
.main2 { max-width: 1920px; margin: 0 auto; }

.main2 .m2tu { width: 50%; *width: 50%; }

.main2 .m2zi { width: 50%; *width: 50%; padding-top: 69px; }

.main2 .m2tu a { display: block; }

.main2 .m2tu a img { display: block; margin: auto; width: 100%; max-width: 960px; max-height: 505px; }

.main2 .m2zin { width: 519px; margin-right: 45px; padding-top: 0px; }

.main2 .m2biao { display: block; font-size: 40px; font-size: 4rem; text-transform: uppercase; line-height: 40px; padding-top: 17px; font-weight: bold; color: #0087d3; }

.main2 .m2zi p a { display: block; font-size: 14px; font-size: 1.4rem; line-height: 30px; margin-top: 30px; max-height: 120px; overflow: hidden; }

.main2 .m2mor { display: inline-block; margin-top: 92px; text-transform: uppercase; font-weight: bold; color: #333; padding-right: 31px; background: url(../images/m2more.png) no-repeat right center; }

.main2 .m2zin2 { margin-left: 45px; }

@media screen and (max-width: 1620px) { .main2 .m2zi { padding-top: 5%; }
  .main2 .m2mor { margin-top: 10%; } }

@media screen and (max-width: 1449px) { .main2 .m2mor { margin-top: 5%; }
  .main2 .m2zi { padding-top: 3%; } }

@media screen and (max-width: 1219px) { .main2 .m2tu { width: 95%; *width: 95%; float: none; margin: 20px auto; }
  .main2 .m2zi { width: 95%; *width: 95%; float: none; margin: 20px auto; }
  .main2 .m2zin { float: none; margin: 0 auto; width: 95%; }
  .main2 .m2zi { padding-top: 0px; margin-bottom: 30px; }
  .main2 .m2mor { margin-top: 10px; }
  .main2 .m2zi p a { margin-top: 10px; } }

@media screen and (max-width: 999px) { .main2 .m2biao { font-size: 30px; font-size: 3rem; padding-top: 0px; line-break: 30px; } }

@media screen and (max-width: 768px) { .main2 .m2biao { font-size: 26px; font-size: 2.6rem; } }

/*==========main3==================*/
.main3 { background: url(../images/main3.png) repeat-y left top; padding-bottom: 34px; }

.main3 .m3_ul { margin-top: 66px; }

.main3 .m3_ul li { padding-left: 122px; width: 340px; width: 340px\0; float: left; box-sizing: border-box; min-height: 110px; margin-bottom: 83px; margin-left: 70px; }

.main3 .m3_ul span { display: block; }

.main3 .m3_ul .titlr { font-size: 16px; font-size: 1.6rem; line-height: 30px; color: #333; margin-top: 20px; font-weight: bold; }

.main3 .m3_ul .p { font-size: 14px; font-size: 1.4rem; line-height: 24px; height: 48px; overflow: hidden; }

.main3 .m3_ul .li1 { background: url(../images/m3tu1.png) no-repeat left top; }

.main3 .m3_ul .li2 { background: url(../images/m3tu2.png) no-repeat left top; }

.main3 .m3_ul .li3 { background: url(../images/m3tu3.png) no-repeat left top; }

.main3 .m3_ul .li4 { background: url(../images/m3tu4.png) no-repeat left top; }

.main3 .m3_ul .li5 { background: url(../images/m3tu5.png) no-repeat left top; }

.main3 .m3_ul .li6 { background: url(../images/m3tu6.png) no-repeat left top; }

.main3 .m3_ul .li1:hover { background: url(../images/m3tu1on.png) no-repeat left top; }

.main3 .m3_ul .li1:hover .titlr { color: #0087d3; }

.main3 .m3_ul .li2:hover { background: url(../images/m3tu2on.png) no-repeat left top; }

.main3 .m3_ul .li2:hover .titlr { color: #0087d3; }

.main3 .m3_ul .li3:hover { background: url(../images/m3tu3on.png) no-repeat left top; }

.main3 .m3_ul .li3:hover .titlr { color: #0087d3; }

.main3 .m3_ul .li4:hover { background: url(../images/m3tu4on.png) no-repeat left top; }

.main3 .m3_ul .li4:hover .titlr { color: #0087d3; }

.main3 .m3_ul .li5:hover { background: url(../images/m3tu5on.png) no-repeat left top; }

.main3 .m3_ul .li5:hover .titlr { color: #0087d3; }

.main3 .m3_ul .li6:hover { background: url(../images/m3tu6on.png) no-repeat left top; }

.main3 .m3_ul .li6:hover .titlr { color: #0087d3; }

@media screen and (max-width: 1219px) { .main3 { background: #f6f7f6; }
  .main3 .m3_ul { margin-top: 66px; }
  .main3 .m3_ul li { width: 31%; margin-left: 1% !important; margin-right: 1%; } }

@media screen and (max-width: 768px) { .main3 .m3_ul { margin-top: 40px; }
  .main3 .m3_ul li { width: 48%; margin-left: 1% !important; margin-right: 1%; padding-left: 0px; min-height: auto; }
  .main3 .m3_ul .p { height: 48px; overflow: hidden; }
  .main3 .m3_ul .li1 { background: none !important; }
  .main3 .m3_ul .li2 { background: none !important; }
  .main3 .m3_ul .li3 { background: none !important; }
  .main3 .m3_ul .li4 { background: none !important; }
  .main3 .m3_ul .li5 { background: none !important; }
  .main3 .m3_ul .li6 { background: none !important; } }

@media screen and (max-width: 600px) { .main3 .m3_ul { margin-top: 30px; }
  .main3 .m3_ul li { width: 100%; float: none; margin-bottom: 30px; } }

/*==========main4==================*/
.main4 { padding-top: 77px; }

.main4 .ttitle { margin-top: 19px; margin-bottom: 37px; }

.main4 .ttitle a { font-size: 40px; font-size: 4rem; font-weight: bold; text-transform: uppercase; }

.main4 .ttitle span { color: #0087d3; }

.main4 p { margin-bottom: 34px; }

.main4 p a { color: #666666; font-size: 14px; font-size: 1.4rem; line-height: 30px; max-height: 77px; overflow: hidden; }

.main4 .imgg { display: block; margin-left: 68px; }

.main4 .more { display: block; background: url(../images/m4more.png) no-repeat center center; color: #fff; width: 140px; height: 45px; line-height: 45px; text-indent: 11px; text-transform: uppercase; }

@media screen and (max-width: 1219px) { .main4 .imgg { width: 45%; } }

@media screen and (max-width: 999px) { .main4 .imgg { width: 100%; float: none; margin: 0 auto; }
  .main4 .imgg img { display: block; margin: 0 auto; }
  .main4 .ttitle a { font-size: 30px; font-size: 3rem; } }

@media screen and (max-width: 768px) { .main4 .ttitle { margin-bottom: 10px; }
  .main4 .ttitle a { font-size: 28px; font-size: 2.8rem; }
  .main4 p { margin-bottom: 10px; }
  .main4 p a { font-size: 14px; font-size: 1.4rem; } }

/*==========main5==================*/
.main5 { background: url(../images/main5.png) no-repeat left top; margin-top: 75px; padding-top: 68px; padding-bottom: 80px; }

.main5 a { color: #fff; text-align: center; }

.main5 .title { font-size: 40px; font-size: 4rem; text-align: center; font-weight: bold; text-transform: uppercase; }

.main5 p { text-align: center; max-width: 961px; margin: 0 auto; margin-top: 10px; margin-bottom: 26px; }

.main5 p a { display: block; line-height: 30px; max-height: 60px; overflow: hidden; }

.main5 .more { margin: 0 auto; text-align: left; color: #333; display: block; background: url(../images/m5more.png) no-repeat center center; width: 140px; height: 45px; line-height: 45px; text-indent: 11px; text-transform: uppercase; }

@media screen and (max-width: 999px) { .main5 .title { font-size: 30px; font-size: 3rem; } }

@media screen and (max-width: 768px) { .main5 .title { font-size: 22px; font-size: 2.2rem; } }

.main6 { background: url(../images/main6.png) no-repeat center top; padding-bottom: 61px; }

.main6 .img-scroll { position: relative; width: 100%; margin-top: 59px; }

.main6 .img-scroll .prev { position: absolute; display: block; width: 18px; height: 32px; z-index: 9; }

.main6 .img-scroll .next { position: absolute; display: block; width: 18px; height: 32px; z-index: 9; }

.main6 .img-scroll .prev { left: 0; cursor: pointer; background: url(../images/arrowleft.png) no-repeat center; }

.main6 .img-scroll .next { right: 0; cursor: pointer; background: url(../images/arrowright.png) no-repeat center; }

.main6 .img-scroll .prev:hover { background: url(../images/arrowlefton.png) no-repeat center; }

.main6 .img-scroll .next:hover { background: url(../images/arrowrighton.png) no-repeat center; }

.main6 .img-scroll .hot { position: relative; max-width: 77px; margin: 0 auto; margin-top: 50px; width: 100%; height: 32px; }

.main6 .img-list { position: relative; width: 100%; overflow: hidden; margin: 0 auto; }

.main6 .img-list ul { width: 1000%; }

.main6 .img-list li { float: left; width: 445px; margin-right: 30px; }

.main6 .img-list li .m3tu { display: block; margin: 10px; }

.main6 .img-list li img { display: block; max-width: 445px; max-height: 363px; width: 100%; margin: 0 auto; }

.main6 .img-list li .m4zi { display: block; margin: 0 auto; max-width: 445px; width: 100%; font-size: 20px; font-size: 2rem; margin-top: 15px; color: #333; line-height: 38px; text-align: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

.main6 .img-list li .m3lien { max-width: 445px; text-align: center; position: relative; margin: 0 auto; overflow: hidden; }

.main6 .img-list li .m3lien:hover .m3tu { box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); }

@media screen and (max-width: 1419px) { .main6 .img-list li { width: 305px; } }

@media screen and (max-width: 999px) { .main6 .img-list li .m3lien .m4fu { top: 0px; opacity: 0; }
  .main6 .img-list { width: 100%; }
  .main6 .img-scroll .hot { position: relative; top: 0px; max-width: 100px; width: 100%; height: 50px; margin-bottom: 20px; }
  .main6 .img-list li { width: 3%; margin-right: .5%; } }

@media screen and (max-width: 768px) { .main6 .img-list li { width: 4.5%; margin-right: 1%; } }

@media screen and (max-width: 400px) { .main6 .img-list li { width: 10%; margin-right: .487%; } }

/*==========main7==================*/
.main7 .m7_ul { margin-top: 66px; padding-bottom: 55px; }

.main7 .m7_ul li { width: 658px; }

.main7 .m7_ul .wrap { max-width: 658px; margin: 0 auto; position: relative; }

.main7 .m7_ul .rili { position: absolute; bottom: 0px; width: 72px; left: 50%; margin-left: -36px; background: #fff; padding-top: 13px; }

.main7 .m7_ul .rili span { display: block; text-align: center; font-size: 12px; font-size: 1.2rem; line-height: 30px; }

.main7 .m7_ul .rili span:first-child { font-size: 22px; font-size: 2.2rem; }

.main7 .m7_ul .rili b { display: block; height: 1px; width: 20px; background: #333; margin: 0 auto; margin-top: 4px; margin-bottom: 0px; }

.main7 .m7_title { text-align: center; font-weight: bold; margin-top: 31px; margin-bottom: 2px; }

.main7 .m7_title a { font-size: 18px; font-size: 1.8rem; line-height: 38px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; }

.main7 p { text-align: center; }

.main7 p a { color: #666666; font-size: 14px; font-size: 1.4rem; line-height: 24px; }

.main7 .more { display: block; margin: 0 auto; width: 130px; width: 135px\0; font-weight: bold; text-transform: uppercase; margin-top: 38px; }

.main7 .more span { display: inline-block; padding-right: 30px; background: url(../images/mreot.png) no-repeat right center; }

@media screen and (max-width: 1419px) { .main7 .m7_ul li { width: 48%; } }

@media screen and (max-width: 648px) { .main7 .m7_ul { margin-top: 30px; }
  .main7 .m7_ul li { width: 100%; float: none; margin: 0 auto; margin-bottom: 30px; }
  .main7 .m7_title { margin-top: 10px; }
  .main7 .more { margin-top: 10px; } }

/*==========about==================*/
.about .abnav2 { text-align: center; background: url(../images/ab2xvxian.png) repeat-x left 48px; padding-bottom: 10px; margin-bottom: 40px; }

.about .abnav2 a { display: inline-block; width: 185px; margin-left: 0px; margin-right: 0px; font-size: 16px; font-size: 1.6rem; line-height: 30px; color: #333333; text-align: center; padding-top: 102px; }

.about .abnav2 a:hover { color: #0087d3; }

.about .ab21 { background: url(../images/ab2nav1.png) no-repeat top center; }

.about .ab22 { background: url(../images/ab2nav2.png) no-repeat top center; }

.about .ab23 { background: url(../images/ab2nav3.png) no-repeat top center; }

.about .ab24 { background: url(../images/ab2nav4.png) no-repeat top center; }

.about .ab21:hover, .about .dangqianab2.ab21 { background: url(../images/ab2nav1h.png) no-repeat top center !important; color: #0087d3; }

.about .ab22:hover, .about .dangqianab2.ab22 { background: url(../images/ab2nav2h.png) no-repeat top center !important; color: #0087d3; }

.about .ab23:hover, .about .dangqianab2.ab23 { background: url(../images/ab2nav3h.png) no-repeat top center !important; color: #0087d3; }

.about .ab24:hover, .about .dangqianab2.ab24 { background: url(../images/ab2nav4h.png) no-repeat top center !important; color: #0087d3; }

.about .title { font-size: 36px; font-size: 3.6rem; font-weight: bold; color: #0087d3; }

.about b { margin-top: 13px; margin-bottom: 22px; display: block; width: 37px; height: 3px; background: #0087d3; }

@media screen and (max-width: 999px) { .about .abnav2 a { width: 22%; margin: 0px 1%; } }

@media screen and (max-width: 640px) { .about .title { font-size: 30px; font-size: 3rem; }
  .about b { margin-top: 5px; margin-bottom: 5px; }
  .about .abnav2 { background: none; }
  .about .abnav2 a { width: 47%; margin: 2% 1%; } }

/*==========about==================*/
.about { margin-bottom: 98px; }

.about .about_left { width: 579px; }

.about .about_left .dan_wrap { position: relative; overflow: hidden; max-width: 579px; max-height: 371px; }

.about .about_left .dan_wrap img { display: block; max-width: 579px; max-height: 371px; width: 100%; margin: 0 auto; }

.about .about_right { width: 596px; }

.about .about_right .title { margin-bottom: 14px; padding-left: 35px; background: url(../images/wenziab.png) no-repeat left 5px; }

.about .about_right .title span { display: block; font-size: 26px; font-size: 2.6rem; line-height: 30px; color: #0087d3; }

.about .about_right p { font-size: 14px; font-size: 1.4rem; color: #666666; line-height: 30px; }

.about .about_right .ti32 { margin-bottom: 22px; font-size: 42px; font-size: 4.2rem; font-weight: bold; text-transform: uppercase; }

.about .ab12_wrap { margin-top: 16px; }

.about .ab12_wrap p { color: #666; line-height: 30px; }

.about .about_ul { margin-top: 28px; }

.about .about_ul li { float: left; margin-left: 10px; }

.about .about_ul li .dan_wrap { position: relative; overflow: hidden; max-width: 393px; margin: 0 auto; }

.about .about_ul li .dan_wrap a { display: block; }

.about .about_ul li .dan_wrap img { display: block; max-width: 393px; max-height: 252px; width: 100%; margin: 0 auto; }

.about .about_ul li .dan_wrap .dan_img2 { position: absolute; top: 100%; left: 0; width: 100%; height: 100%; }

.about .about_ul li .dan_wrap .dan_img3 { position: absolute; bottom: 0; left: 0; width: 100%; z-index: 9999; height: 53px; line-height: 53px; background: url(../images/aboutbj.png) repeat center center; }

.about .about_ul li .dan_wrap .dan_img3 span { text-align: left; box-sizing: border-box; padding: 0px 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; color: #fff; display: block; }

.about .about_ul li:hover .dan_wrap .dan_img2 { top: 0px; }

.about .about_left:hover .dan_wrap .dan_img2 { top: 0px; }

@media screen and (max-width: 1219px) { .about .about_left { width: 49%; }
  .about .about_right { width: 49%; }
  .about .about_ul li { width: 31%; margin-left: 1% !important; margin-right: 1%; } }

@media screen and (max-width: 999px) { .about .about_right .ti32 { font-size: 30px; font-size: 3rem; }
  .about .about_left { width: 100%; float: none; }
  .about .about_right { width: 100%; float: none; margin-top: 20px; }
  .about .about_ul li .dan_wrap .dan_img2 { top: 0px; opacity: 0; } }

@media screen and (max-width: 768px) { .about .about_right .ti32 { font-size: 24px; font-size: 2.4rem; }
  .about .about_right .title { margin-bottom: 10px; }
  .about .about_ul li { width: 100%; margin: 0 auto; margin-bottom: 20px; }
  .about .about .topcat .item { margin-left: 20px !important; margin-right: 20px; } }

.faq { padding-bottom: 45px; }

.faq li { padding-left: 52px; background: url(../images/faq.png) no-repeat left top; border-bottom: 1px solid #cccccc; padding-bottom: 21px; margin-bottom: 26px; }

.faq .totle3 { font-size: 16px; font-size: 1.6rem; line-height: 30px; }

.faq p { color: #666666; line-height: 30px; margin-top: 13px; }

/***********************************************************************  video  ****************************************************************/
.ab3_wrap { padding-bottom: 60px; }

.vilie { width: 382px; float: left; margin-left: 27px; margin-bottom: 29px; }

.vitu { position: relative; padding-bottom: 53.6%; height: 0; overflow: hidden; }

.vitu iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none; }

.vitu iframe body { margin: 0px !important; }

.vizi { background: #ededed; padding: 7px 10px; padding-top: 8px; }

.vizi p { font-size: 16px; font-size: 1.6rem; padding-left: 46px; background: url(../images/bofang.png) no-repeat left center; line-height: 46px; height: 46px; overflow: hidden; }

@media screen and (min-width: 1000px) and (max-width: 1219px) { .vilie { width: 31.333%; margin-left: 1% !important; margin-right: 1%; margin-bottom: 0px; } }

@media screen and (max-width: 999px) { .vimain { padding-bottom: 28px; }
  .vilie { width: 31.333%; margin-left: 1% !important; margin-right: 1%; }
  .vizi p { font-size: 14px; font-size: 1.4rem; } }

@media screen and (max-width: 749px) { .ab3_wrap { padding-bottom: 20px; }
  .vimain .title4 { margin-bottom: 0px; }
  .vimain .title3 { font-size: 30px; font-size: 3rem; margin-top: 0px; }
  .vilie { width: 48%; }
  .vizi p { background: none; padding-left: 0px; height: 30px; line-height: 30px; } }

.ablie2 { width: 245px; float: left; margin-left: 73px; margin-bottom: 41px; }

.abtu2 { position: relative; overflow: hidden; display: block; }

.abtu2 img { width: 100%; max-width: 245px; max-height: 349px; display: block; }

.fangda { display: block; position: absolute; width: 100%; height: 100%; top: 100%; left: 0px; transition: 0.5s; }

.ablie2 p { text-align: center; margin-top: 16px; overflow: hidden; font-family: Verdana, Geneva, Tahoma, sans-serif; }

.ablie2:hover .fangda { top: 0px; }

@media screen and (max-width: 1219px) { .ablie2 { width: 31%; margin-left: 1% !important; margin-right: 1%; } }

@media screen and (max-width: 999px) { .ablie2 .fangda { top: 0px; opacity: 0; } }

@media screen and (max-width: 648px) { .ablie2 { width: 48%; margin-left: 1% !important; margin-right: 1%; margin-bottom: 20px; } }

.ab4_wrapq .img-scroll { position: relative; margin: 0 auto; width: 100%; margin-top: 19px; }

.ab4_wrapq .img-scroll .prev, .ab4_wrapq .img-scroll .next { position: absolute; display: block; width: 31px; height: 31px; top: 0; color: #fff; text-align: center; line-height: 100px; }

.ab4_wrapq .img-scroll .prev { right: 0; margin-right: 40px; cursor: pointer; background: url("../images/zss.png") no-repeat; }

.ab4_wrapq .img-scroll .next { right: 0; margin-right: 3px; cursor: pointer; background: url("../images/zsx.png") no-repeat; }

.ab4_wrapq .img-scroll .prev:hover { background: url("../images/zss2.png") no-repeat; }

.ab4_wrapq .img-scroll .next:hover { background: url("../images/zsx2.png") no-repeat; }

.ab4_wrapq .img-list { position: relative; width: 100%; overflow: hidden; margin: 0 auto; padding-top: 58px; }

.ab4_wrapq .img-list ul { width: 999999px; }

.ab4_wrapq .img-list li { float: left; display: block; width: 358px; position: relative; position: relative; margin-right: 63px; }

.ab4_wrapq .hzs img { width: 100%; max-width: 358px; max-height: 248px; }

.ab4_wrapq .hzsp { text-align: center; display: block; line-height: 36px; margin-top: 5px; }

@media screen and (min-width: 1000px) and (max-width: 1219px) { .ab4_wrapq .img-list li { width: 285px; } }

@media screen and (max-width: 999px) { .ab4_wrapq .img-list ul { width: 10000%; }
  .ab4_wrapq .img-list li { width: 0.31333%; margin: 0 0.01%; }
  .ab4_wrapq .img-scroll { margin-top: 20px; }
  .ab4_wrapq .img-list { padding-top: 55px; }
  .ab4_wrapq .fangda { top: 0; background: none; } }

@media screen and (max-width: 639px) { .ab4_wrapq .img-list li { width: 0.48%; margin: 0 0.01%; } }

/*==========product==================*/
.product { /*list*/ }

.product .pr_r { width: 828px; padding-bottom: 100px; }

.product .list { width: 321px; }

.product .pr_ul { padding-bottom: 60px; }

.product .pr_ul li { float: left; margin-left: 18px; margin-bottom: 21px; }

.product .pr_ul li:hover .img { box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5); }

.product .pr_ul li:hover .chanmor a { background: #0087d3; color: #fff; }

.product .pr_ul .wrap { max-width: 264px; margin: 0 auto; overflow: hidden; }

.product .pr_ul .img { display: block; border: 1px solid #cccccc; box-sizing: border-box; margin: 10px; }

.product .pr_ul .img img { display: block; max-width: 262px; max-height: 240px; width: 100%; margin: 0 auto; }

.product .pr_ul .przz { margin-top: 16px; margin-bottom: 12px; }

.product .pr_ul .przz a { font-size: 16px; font-size: 1.6rem; display: block; text-align: center; line-height: 30px; display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

.product .pr_ul .chanmor { text-align: center; }

.product .pr_ul .chanmor a { margin-left: 10px; display: inline-block; font-size: 14px; font-size: 1.4rem; text-align: center; width: 139px; height: 31px; line-height: 29px; border: 1px solid #0087d3; color: #0087d3; font-size: 14px; font-size: 1.4rem; color: #0087d3; }

.product .pr_ul .chanlien input { display: inline-block; left: 9px; top: 11px; cursor: pointer; margin-right: 5px; }

.product .list .title1 { width: 100%; }

.product .list .title1 a { display: block; width: 100%; height: 50px; line-height: 50px; text-transform: uppercase; background-color: #0087d3; font-size: 24px; font-size: 2.4rem; color: #0087d3; font-weight: bold; color: #fff; text-align: center; }

.product .list .title2 { width: 100%; margin-top: 51px; margin-bottom: 2px; }

.product .list .title2 a { display: block; padding-left: 16px; height: 46px; line-height: 46px; text-transform: uppercase; background-color: #0087d3; font-size: 22px; font-size: 2.2rem; color: #fff; font-weight: bold; }

.product .list .subNav1 { cursor: pointer; background: #e4e5e6; margin-bottom: 0px; }

.product .list .subNav1.div1 { margin-top: 0px; padding-top: 0px; }

.product .list .subNav1 a { line-height: 41px; padding-left: 13px; color: #333333; transition: 0s; }

.product .list .subNav1:hover { background: #0087d3; color: #fff; }

.product .list .subNav1:hover a { color: #fff !important; }

.product .list .subNav1.subNav1on { background: #0087d3; color: #fff; }

.product .list .subNav1.subNav1on a { color: #fff !important; }

.product .list .subNavBox { width: 100%; }

.product .list .subNav { cursor: pointer; background: #e4e5e6 url(../images/parrow.png) no-repeat 98% 12px; margin-top: 10px; margin-bottom: 0px; }

.product .list .subNav.div1 { margin-top: 0px; padding-top: 4px; }

.product .list .subNav a { line-height: 41px; padding-left: 13px; color: #333333; transition: 0s; }

.product .list .subNav:hover { background: #0087d3 url(../images/parrowon.png) no-repeat 98% 12px; color: #fff; }

.product .list .subNav:hover a { color: #fff; }

.product .list .currentDd { background: #0087d3 url(../images/parrowon.png) no-repeat 98% 12px; }

.product .list .currentDd a { color: #fff; }

.product .list .navContent { padding-top: 8px; margin-top: 2px; display: none; background: #fafafa; position: relative; padding-bottom: 11px; top: 0px; }

.product .list .navContent > li > a { background: url(../images/peirb.png) no-repeat 17px center; display: block; text-transform: uppercase; font-size: 14px; font-size: 1.4rem; line-height: 30px; color: #333333; padding-left: 43px; }

.product .list .navContent > li a:hover { color: #0087d3; }

.product .div_p { padding-left: 63px; }

.product .div_p p a { display: block; font-size: 14px; font-size: 1.4rem; line-height: 30px; }

.product .titlems { position: relative; }

.product .titlems .dan_p { display: none; position: absolute; right: 5px; top: 15px; display: none; }

.product .titlems .dan_p span { margin-bottom: 2px; display: block; width: 25px; height: 4px; background-color: #FFF; }

.product .pro_new { background: #eeeeee; padding: 16px; }

.product .pro_new .title { margin-top: 7px; margin-bottom: 13px; display: block; line-height: 24px; font-weight: bold; }

.product .pro_new a.title24 { font-size: 15px; font-size: 1.5rem; margin-bottom: 24px; display: block; line-height: 30px; padding-left: 34px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background: url(../images/fapd.png) no-repeat left top; font-weight: bold; }

.product .pro_new p a { display: block; color: #666666; font-size: 14px; font-size: 1.4rem; line-height: 24px; max-height: 48px; overflow: hidden; }

.product .pro_new .more { display: block; margin-top: 9px; color: #333; font-size: 14px; font-size: 1.4rem; line-height: 30px; }

.product .pro_new .more2 { color: #0087d3; }

.product .pro_new2 { border-top: 1px solid #cccccc; }

.product .pro_new .more:hover { color: #0087d3; }

@media screen and (max-width: 1219px) { .product .pr_r { width: 630px; }
  .product .list { width: 321px; }
  .product .pr_ul li { float: left; width: 31%; margin-left: 1% !important; margin-right: 1%; }
  .product .pr_ul .peo_wrap { padding-left: 10px; padding-right: 10px; } }

@media screen and (max-width: 999px) { .product .pr_ul { padding-bottom: 20px; }
  .product .pr_r { width: 100%; float: none; margin-bottom: 0px; padding-bottom: 30px; }
  .product .list { width: 100%; float: none; margin-bottom: 30px; }
  .product .list .subNavBox { display: none; }
  .product .titlems .dan_p { display: block; } }

@media screen and (max-width: 768px) { .product .list .title1 a { font-size: 18px; font-size: 1.8rem; }
  .product .pr_ul li { width: 48%; }
  .product .pr_ul .wenzi .more { max-width: 120px; width: 80%; font-size: 14px; font-size: 1.4rem; } }

@media screen and (max-width: 370px) { .product .pr_ul .chanmor a { width: 100px; } }

.product .list { margin-bottom: 20px; }

/*pr_r */
.pr_r { /*mouse hands*/ /* teer.js tsScrollResize offsetWidth*3 */ }

.pr_r #tsShopContainer li, .pr_r #tsShopContainer img { vertical-align: top; }

.pr_r #tsShopContainer { position: relative; float: left; width: 419px; }

.pr_r #tsShopContainer #tsImgS { text-align: center; width: 100%; position: relative; }

.pr_r #tsShopContainer #tsImgS a { display: block; text-align: center; margin: 0px auto; width: 419px !important; }

.pr_r #tsShopContainer #tsImgS img { border: 1px solid #cccccc; }

.pr_r #tsShopContainer #tsPicContainer { width: 100%; position: relative; height: 99px; margin-top: 11px; }

.pr_r #tsShopContainer #tsPicContainer #tsImgSArrL { width: 21px; position: absolute; top: 0px; left: 0px; cursor: pointer; height: 99px; background: url(../images/prleft.png) no-repeat left center; }

.pr_r #tsShopContainer #tsPicContainer #tsImgSArrR { width: 21px; position: absolute; top: 0px; right: 0px; cursor: pointer; height: 99px; background: url(../images/prright.png) no-repeat right center; }

.pr_r #tsShopContainer #tsPicContainer #tsImgSArrL:hover { background: url(../images/prlefth.png) no-repeat left center; }

.pr_r #tsShopContainer #tsPicContainer #tsImgSArrR:hover { background: url(../images/prrighth.png) no-repeat right center; }

.pr_r #tsShopContainer #tsPicContainer #tsImgSCon { position: absolute; top: 1px; left: 40px; width: 1px; overflow: hidden; height: 99px; }

.pr_r #tsShopContainer #tsPicContainer #tsImgSCon ul { width: 100%; overflow: hidden; }

.pr_r #tsShopContainer #tsPicContainer #tsImgSCon li { width: 117px; float: left; cursor: pointer; margin-left: 0px; margin-bottom: 0; }

.pr_r #tsShopContainer #tsPicContainer #tsImgSCon li a { display: block; width: 99px; }

.pr_r #tsShopContainer #tsPicContainer #tsImgSCon li img { display: block; border: 1px solid #cccccc; box-sizing: border-box; width: 104px; height: 94px; }

.pr_r .MagicZoomBigImageCont { border: 1px solid #f0f0f0; background: #FFF; }

.pr_r .MagicZoomPup { border: 0px solid #aaa; background: #ffffff; }

.pr_r .MagicZoomMain { text-align: center !important; width: 92px; }

.pr_r .MagicZoomBigImageCont img { max-width: 838px; display: block; border: 0px solid #aaa; }

.pr_r .content { width: 357px; }

.pr_r h1 { font-size: 24px; font-size: 2.4rem; line-height: 43px; font-weight: bold; margin: 0 auto; color: #0087d3; text-transform: uppercase; }

.pr_r .btnt { max-width: 330px; width: 100%; margin-top: 73px; margin-bottom: 24px; }

.pr_r .btnt a, .pr_r .btnt span { display: block; font-size: 14px; font-size: 1.4rem; color: #0087d3; border: 1px solid #0087d3; width: 138px; height: 31px; line-height: 31px; text-align: center; background: transparent; }

.pr_r .btnt a:hover, .pr_r .btnt span:hover { background: #0087d3; color: #fff !important; }

.pr_r .share1 { position: relative; top: 0px; display: inline-block; width: 235px; margin-top: 4px; }

.pr_r .share1 .span4 { font-size: 14px; font-size: 1.4rem; color: #666666; height: 30px; line-height: 30px; }

.pr_r .at-resp-share-element .at-icon { width: 26px !important; height: 26px !important; }

.pr_r .at-style-responsive .at-share-btn { padding: 0 !important; border-radius: 2px !important; }

.pr_r .at-resp-share-element .at-share-btn .at-icon-wrapper { width: 26px !important; height: 26px !important; }

.pr_r .at-resp-share-element .at-share-btn { margin-bottom: 0 !important; margin-right: 3px !important; }

.pr_r .img980 { display: none; }

.pr_r .img980 .img-scroll { position: relative; width: 100%; }

.pr_r .img980 .img-scroll .prev { position: absolute; display: block; width: 16px; height: 16px; top: 50%; margin-top: -8px; z-index: 99; }

.pr_r .img980 .img-scroll .next { position: absolute; display: block; width: 16px; height: 16px; top: 50%; margin-top: -8px; z-index: 99; }

.pr_r .img980 .img-scroll .prev { left: 0; cursor: pointer; background: url(../images/prleft.png) no-repeat center; }

.pr_r .img980 .img-scroll .next { right: 0; cursor: pointer; background: url(../images/prright.png) no-repeat center; }

.pr_r .img980 .img-scroll .prev:hover { left: 0; cursor: pointer; background: url(../images/prlefth.png) no-repeat center; }

.pr_r .img980 .img-scroll .next:hover { right: 0; cursor: pointer; background: url(../images/prrighth.png) no-repeat center; }

.pr_r .img980 .img-list { position: relative; width: 100%; overflow: hidden; margin: 0 auto; }

.pr_r .img980 .img-list ul { width: 1000%; }

.pr_r .img980 .img-list li { float: left; width: 3%; margin-right: .5%; }

.pr_r .img980 .img-list li .m3lien { max-width: 213px; text-align: center; position: relative; overflow: hidden; box-sizing: border-box; border: 1px solid #ccc; margin: 0 auto; }

.pr_r .img980 .img-list li .m3lien img { display: block; max-width: 213px; max-height: 213px; width: 100%; margin: 0 auto; }

@media screen and (max-width: 1220px) { .pr_r .MagicZoomBigImageCont { display: none !important; }
  .pr_r .MagicZoomMain { display: none !important; }
  .pr_r .MagicZoomPup { display: none !important; }
  .pr_r .content { width: 100%; float: none; margin-top: 20px; }
  .pr_r #tsShopContainer { margin: 0 auto; max-width: 437px; width: 100%; float: none; } }

@media screen and (max-width: 999px) { .pr_r #tsShopContainer { display: none; }
  .pr_r .img980 { display: block; }
  .pr_r .img980 .img-scroll { margin-top: 0px; }
  .pr_r .btnt { margin-top: 20px; } }

@media screen and (max-width: 768px) { .pr_r .img980 .img-list li { width: 4.5%; margin-right: 1%; } }

.pro_x { font-size: 14px; font-size: 1.4rem; color: #666666; line-height: 30px; margin-top: 20px; }

.pro_x p, .pro_x span, .pro_x li { color: #666666; }

.pro_x a { color: #0087d3; line-height: 24px; }

.pro_x img { display: block; max-width: 100%; margin: 0 auto; }

.pro_x iframe { display: block; max-width: 100%; margin: 0 auto; }

.pro_x table { width: 90%; border-collapse: collapse; line-height: 22px; border: 1px solid #ccc; margin-left: auto; margin-right: auto; }

.pro_x table tr td { padding: 3px; border: 1px solid #ccc; background: #fff; }

.pro_x2 { font-size: 14px; font-size: 1.4rem; color: #666666; line-height: 30px; margin-top: 20px; }

.pro_x2 p, .pro_x2 span, .pro_x2 li { color: #666666; }

.pro_x2 a { color: #0087d3; line-height: 24px; }

.pro_x2 img { display: block; max-width: 100%; margin: 0 auto; }

.pro_x2 iframe { display: block; max-width: 100%; margin: 0 auto; }

.pro_x2 table { width: 90%; border-collapse: collapse; line-height: 22px; border: 1px solid #ccc; margin-left: auto; margin-right: auto; }

.pro_x2 table tr td { padding: 3px; border: 1px solid #ccc; background: #fff; }

.product #tab { overflow: hidden; width: 100%; margin-top: 41px; }

.product #tab li { height: 52px; line-height: 52px; text-align: center; float: left; cursor: pointer; width: 50%; background: #f5f5f5; font-weight: bold; font-size: 24px; font-size: 2.4rem; }

.product #tab li:hover { background: #666666; color: #fff; }

.product #tab li.current { background: #666666; color: #fff; }

.product #maln { margin-left: 0 !important; }

.product #content .chanxn { display: none; padding-top: 15px; width: 100%; }

.product .por_wrotr { font-size: 14px; font-size: 1.4rem; color: #666666; line-height: 30px; margin-bottom: 20px; }

.product .por_wrotr p, .product .por_wrotr span, .product .por_wrotr li { color: #666666; }

.product .por_wrotr a { color: #0087d3; line-height: 24px; }

.product .por_wrotr img { display: block; max-width: 100%; margin: 0 auto; }

.product .por_wrotr iframe { display: block; max-width: 100%; margin: 0 auto; }

.product .por_wrotr table { width: 90%; border-collapse: collapse; line-height: 22px; border: 1px solid #ccc; margin-left: auto; margin-right: auto; }

.product .por_wrotr table tr td { padding: 3px; border: 1px solid #ccc; background: #fff; }

.product .down { padding-top: 19px; }

.product .down p { padding-bottom: 20px; margin-bottom: 30px; position: relative; background: url(../images/down.png) no-repeat left 5px; padding-left: 34px; padding-right: 140px; border-bottom: 1px dashed #ccc; font-size: 16px; font-size: 1.6rem; line-height: 30px; }

.product .down a { display: block; position: absolute; right: 0px; top: 0px; background: url(../images/wnono.png) no-repeat left top; width: 138px; height: 36px; line-height: 36px; color: #fff; text-indent: 50px; font-size: 16px; font-size: 1.6rem; }

.product .m-mpro { margin-top: 30px; }

.product .m-mpro .box-hd { position: relative; padding-bottom: 7px; line-height: 30px; font-size: 26px; font-size: 2.6rem; font-weight: bold; color: #0087d3; border-bottom: 1px solid #ccc; }

.product .m-mpro .box-title { font-weight: bold; font-size: 20px; font-size: 2rem; }

.product .m-mpro .box-btn { position: absolute; top: 6px; width: 13px; height: 16px; width: 16px\0; height: 21px\0; background-repeat: no-repeat; background-position: center; }

.product .m-mpro .box-btn.prev { right: 33px; background-image: url("../images/prleft.png"); background-size: contain; }

.product .m-mpro .box-btn.prev:hover { background-image: url("../images/prlefth.png"); background-size: contain; }

.product .m-mpro .box-btn.next { right: 0; background-image: url("../images/prright.png"); background-size: contain; }

.product .m-mpro .box-btn.next:hover { background-image: url("../images/prrighth.png"); background-size: contain; }

.product .m-mpro .box-bd { margin-top: 30px; overflow: hidden; background-size: contain; }

.product .m-mpro .box-list { width: 1000%; overflow: hidden; }

.product .m-mpro .box-list li { margin-left: 0px; margin-right: 18px; }

@media screen and (max-width: 1219px) { .product .m-mpro .box-list li { width: 3%; margin-right: .5%; margin-left: 0px !important; } }

@media screen and (max-width: 999px) { .product .m-mpro .box-list { padding-bottom: 0px; }
  .product .m-mpro .box-list li { width: 3%; margin-right: .5%; } }

@media screen and (max-width: 768px) { .product .img-scroll { margin-top: 20px; }
  .product .m-mpro .box-list li { width: 4.5%; margin-right: 1%; } }

@media screen and (max-width: 640px) { .product .down { padding-top: 19px; }
  .product .down p { padding-right: 0px; }
  .product .down a { display: block; position: relative; right: 0px; top: 0px; margin-top: 10px; }
  .product #tab li { font-size: 20px; font-size: 2rem; }
  .product .m-prob1 .prob-info-title { font-size: 20px; font-size: 2rem; }
  .product .m-mpro .box-title { font-size: 20px; font-size: 2rem; }
  .product .m-mpro .list-item { margin-bottom: 0px; margin-left: .1%; margin-right: .1% !important; width: 4.8%; }
  .product .m-mpro .item-title { font-size: 14px; font-size: 1.4rem; }
  .product .m-mpro .item-desc a { font-size: 14px; font-size: 1.4rem; } }

@media screen and (max-width: 500px) { .product #tab li { width: 100%; float: none; font-size: 14px; font-size: 1.4rem; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; padding: 0px 10px; box-sizing: border-box; } }

/*==========application==================*/
.application { padding-bottom: 100px; }

.application .news_wrp { margin-bottom: 40px; }

.application .nelie { border: 1px solid #f6f6f6; }

.application .nelie1 { padding-top: 10px; padding-left: 30px; padding-right: 10px; padding-bottom: 10px; background: #f6f6f6; margin-top: 35px; }

.application .nelie2 { padding-top: 10px; padding-right: 30px; padding-left: 10px; background: #f6f6f6; padding-bottom: 10px; margin-top: 35px; }

.application .nelie1 .nelietu { float: right; }

.application .nelie1 .nelien { float: left; }

.application .nelie2 .nelietu { float: left; }

.application .nelie2 .nelien { float: right; }

.application .nelietu { display: block; width: 413px; }

.application .nelietu img { display: block; max-width: 413px; max-height: 336px; width: 100%; margin: 0 auto; }

.application .nelien { width: 625px; padding-top: 54px; }

.application .neliebiao { display: block; font-size: 30px; font-size: 3rem; color: #0087d3; font-weight: bold; text-transform: uppercase; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; line-height: 30px; }

.application .neliebiao:hover { color: #0087d3; }

.application .nelien p { margin-top: 23px; margin-bottom: 28px; }

.application .nelien p a { display: block; color: #4d4d4d; line-height: 24px; max-height: 72px; overflow: hidden; }

.application .nelien p a:hover { color: #0087d3; }

.application .neliemor { margin-top: 69px; }

.application .neliemor a { border-radius: 50px; display: block; width: 160px; height: 38px; border: 1px solid #0087d3; color: #333333; line-height: 38px; text-align: center; font-weight: bold; }

.application .neliemor a:hover { background: #0087d3; color: #fff; }

.application .nelie2 span { float: left; margin-left: 10px; }

@media screen and (max-width: 1219px) { .application .nelie1 .nelien { width: 513px; }
  .application .nelie1 .nelietu { width: 300px; }
  .application .nelien { width: 513px; }
  .application .nelietu { width: 300px; } }

@media screen and (max-width: 999px) { .application { padding-bottom: 80px; }
  .application .nelie1 .nelien { width: 100%; float: none; margin: 0 auto; }
  .application .nelie1 .nelietu { width: 100%; float: none; margin: 0 auto; margin-bottom: 20px; }
  .application .nelien { width: 100%; float: none; margin: 0 auto; padding-top: 10px; }
  .application .nelietu { width: 100%; float: none; margin: 0 auto; margin-bottom: 20px; }
  .application .nelien p { margin: 10px 0px; }
  .application .nelien p a { max-height: 108px; overflow: hidden; }
  .application .neliemor { width: 100%; margin-top: 10px; }
  .application .nelie1 { padding: 20px; }
  .application .nelie2 { padding: 20px; } }

@media screen and (max-width: 768px) { .application { padding-bottom: 30px; }
  .application .nelie1 { padding: 10px; }
  .application .nelie2 { padding: 10px; }
  .application .neliebiao { font-size: 20px; font-size: 2rem; }
  .application .nelien p a { font-size: 14px; font-size: 1.4rem; line-height: 30px; max-height: 90px; overflow: hidden; }
  .application .neliemor a { max-width: 278px; width: 100%; } }

.new_topwrap { border-left: 1px solid #dcdcdc; margin-bottom: 60px; }

.new_topwrap a { border-right: 1px solid #dcdcdc; display: block; float: left; width: 298px; height: 60px; line-height: 60px; text-align: center; background: #f0f0f0; font-size: 16px; font-size: 1.6rem; }

.new_topwrap a:hover, .new_topwrap a.on { background: #333; color: #fff; }

@media screen and (max-width: 1219px) { .new_topwrap a { width: 25%; box-sizing: border-box; } }

@media screen and (max-width: 999px) { .new_topwrap a { width: 33.33%; box-sizing: border-box; } }

@media screen and (max-width: 640px) { .new_topwrap { border-top: 1px solid #dcdcdc; }
  .new_topwrap a { width: 100%; float: none; box-sizing: border-box; border-top: 1px solid #dcdcdc; } }

/*==========application==================*/
.application { position: relative; }

.application .title1 { text-align: center; }

.application .title1 h1 { font-size: 24px; font-size: 2.4rem; font-weight: bold; }

.application .news2x { font-size: 14px; font-size: 1.4rem; color: #333; line-height: 30px; margin-top: 30px; }

.application .news2x a { color: #0087d3; line-height: 24px; }

.application .news2x img { display: block; max-width: 100%; margin: 0 auto; }

.application .news2x iframe { display: block; max-width: 100%; margin: 0 auto; }

.application .news2x table { width: 90%; border-collapse: collapse; line-height: 22px; border: 1px solid #ccc; margin-left: auto; margin-right: auto; }

.application .news2x table tr td { padding: 3px; border: 1px solid #ccc; background: #fff; }

.application .updown { margin-top: 20px; padding-top: 31px; border-top: 1px solid #cccccc; }

.application .updown .up { margin-top: 0px; margin-bottom: 0px; }

.application .updown p { line-height: 32px; font-size: 14px; font-size: 1.4rem; color: #333333; }

.application .updown p .img { display: inline-block; }

.application .updown p .img img { display: inline-block; position: relative; top: 7px; }

.application .updown p .on { color: #0087d3; }

.application .updown p span { font-size: 14px; font-size: 1.4rem; color: #333333; }

.application .updown p a { font-size: 14px; font-size: 1.4rem; color: #333333; }

.application .updown p a:hover { color: #0087d3; }

@media screen and (max-width: 768px) { .application .updown { margin-bottom: 40px; }
  .application .title1 h1 { font-size: 16px; font-size: 1.6rem; line-height: 30px; } }

.m-ctusinfo { width: 40%; }

.m-ctusinfo .box-title { line-height: 26px; font-size: 18px; font-size: 1.8rem; color: #333333; }

.m-ctusinfo .list-item { padding-left: 32px; line-height: 44px; font-size: 14px; font-size: 1.4rem; color: #333333; background-repeat: no-repeat; }

.m-ctusinfo .list-item a { color: #333; }

.m-ctusinfo .list-item a:hover { color: #0087d3; }

.m-ctusinfo .list-item-1 { background: url("../images/cicon1.png") no-repeat left 15px; }

.m-ctusinfo .list-item-2 { background: url("../images/cicon2.png") no-repeat left 10px; }

.m-ctusinfo .list-item-3 { background: url("../images/cicon3.png") no-repeat left 10px; }

.m-ctusinfo .list-item-4 { background: url("../images/cicon4.png") no-repeat left 16px; }

.m-ctusinfo .list-item-5 { background: url("../images/cicon5.png") no-repeat left 10px; }

.m-ctusinfo .list-item-6 { background: url("../images/cicon6.png") no-repeat left 10px; }

.m-ctusinfo .list-item-7 { background: url("../images/cicon7.png") no-repeat left 10px; }

@media screen and (max-width: 959px) { .m-ctusinfo { float: none; width: 100%; } }

.m-ctusform { float: right; width: 55.83333%; overflow: hidden; }

.m-ctusform .form-item { float: left; position: relative; margin-bottom: 22px; padding-left: 22px; line-height: 0; font-size: 0; box-sizing: border-box; overflow: hidden; }

.m-ctusform .form-item.important { background: url("../images/star2.png") no-repeat 8px 28px; }

.m-ctusform .form-item-1 { width: 50%; }

.m-ctusform .form-item-2 { width: 100%; }

.m-ctusform .form-item-2.important { background: url("../images/star2.png") no-repeat 8px 18px; }

.m-ctusform .form-item-3 { margin-right: 3.28358%; width: 29.85075%; }

.m-ctusform .form-item-4 { width: 26.26866%; height: 54px; padding-left: 0; line-height: 52px; text-align: center; background-color: transparent; border-radius: 0; background-color: #fff; overflow: hidden; box-sizing: border-box; border: 1px solid #d2d2d2; }

.m-ctusform .form-item-4 img { max-height: 52px; vertical-align: middle; }

.m-ctusform .form-item-5 { float: right; border: none; width: 37.31343%; padding-left: 0; }

.m-ctusform .item-btn1 { width: 100%; height: 54px; padding-left: 0; line-height: 54px; color: #ffffff; font-size: 14px; font-size: 1.4rem; text-align: center; text-transform: uppercase; background-color: #0087d3; }

.m-ctusform .item-text { width: 100%; height: 54px; line-height: 52px; padding-left: 14px; padding-right: 14px; font-size: 14px; font-size: 1.4rem; color: #666666; box-sizing: border-box; border: 1px solid #d2d2d2; }

.m-ctusform .item-text2 { height: 193px; line-height: 40px; }

@media screen and (max-width: 959px) { .m-ctusform { float: none; margin-top: 30px; width: 100%; } }

@media screen and (max-width: 767px) { .m-ctusform .form-item { margin-bottom: 15px; width: 100%; }
  .m-ctusform .form-item.important { background-position: 5px 13px; }
  .m-ctusform .item-text { height: 35px; line-height: 35px; font-size: 14px; font-size: 1.4rem; }
  .m-ctusform .item-text2 { height: 140px; } }

.m-ctusmap { margin-top: 29px; margin-bottom: 100px; }

.m-ctusmap img { display: block; max-width: 1200px; width: 100%; margin: 0 auto; }

@media screen and (max-width: 999px) { .m-ctusmap { margin-top: 30px; margin-bottom: 30px; }
  .m-ctusinfo .box-title { font-size: 16px; font-size: 1.6rem; } }

/* ======================================== news ======================================== */
/*new*/
.news { padding-bottom: 100px; }

.new_dl { padding-bottom: 53px; }

.new_wrap { padding-bottom: 41px; }

.new_dl.new_dl2 { margin-left: 69px; }

.new_dl dt { width: 450px; }

.new_dl dt a { display: block; }

.new_dl dt a img { display: block; max-width: 450px; width: 100%; }

.new_dl dd { width: 577px; margin-left: 56px; padding-top: 0px; }

.new_dl dd .title { font-family: Tahoma; font-size: 72px; font-size: 7.2rem; color: #d0d0d0; margin-top: -12px; }

.new_dl.new_dl2 dd .title { margin-top: -19px; }

.new_dl dd .title span { font-size: 18px; font-size: 1.8rem; color: #999999; }

.new_dl dd .title2 { padding-top: 25px; padding-bottom: 9px; display: block; font-size: 16px; font-size: 1.6rem; line-height: 30px; font-weight: bold; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

.new_dl dd .title3 { display: block; line-height: 30px; max-height: 90px; overflow: hidden; margin-bottom: 33px; color: #666666; font-size: 14px; font-size: 1.4rem; }

.new_dl dd .more { display: block; text-transform: uppercase; font-family: Tahoma; border: 1px solid #0087d3; color: #0087d3; border-radius: 50px; font-size: 14px; font-size: 1.4rem; width: 141px; height: 42px; line-height: 42px; text-align: center; }

.new_dl dd .more:hover { background: #0087d3; color: #fff; }

.new_dl dd a:hover { color: #0087d3; }

@media screen and (max-width: 1219px) { .new_dl dt { width: 377px; }
  .new_dl dd { width: 530px; padding-top: 0px; }
  .new_dl.new_dl2 { margin-left: 0px; } }

@media screen and (max-width: 999px) { .new .page { margin: 20px auto 30px; }
  .new_dl dt { width: 100%; }
  .new_dl dd { width: 100%; margin-left: 0px; padding-top: 0px; }
  .new_dl.new_dl2 dd .title { margin-top: 10px; }
  .new_dl dd .title { margin-top: 10px; }
  .new_dl { padding-bottom: 40px; }
  .new_dl dt a img { display: block; margin: 0 auto; }
  .new_dl dd .more { height: 30px; line-height: 30px; } }

@media screen and (max-width: 768px) { .new_dl dd .title span { font-size: 16px; font-size: 1.6rem; }
  .new_dl dd .title2 { font-size: 14px; font-size: 1.4rem; }
  .new_dl dd .title3 { font-size: 13px; font-size: 1.3rem; } }

@media screen and (max-width: 640px) { .new_dl { margin-top: 0px; }
  .new_dl dd .title2 { padding-top: 10px; }
  .new_dl dd .title { font-size: 40px; font-size: 4rem; padding-top: 10px; } }

/* new2 */
.new2 { padding-bottom: 30px; }

.new2 .new2_left { width: 798px; }

.new2 .xqy { position: relative; }

.new2 .xqy .title1 h1 { font-size: 16px; font-size: 1.6rem; color: #333; font-weight: bold; line-height: 30px; }

.new2 .xqy .time { margin-top: 9px; margin-bottom: 18px; }

.new2 .xqy .time span { font-size: 14px; font-size: 1.4rem; color: #999; font-weight: normal; display: inline-block; line-height: 26px; }

.new2 .newsx { color: #666666; font-size: 14px; font-size: 1.4rem; line-height: 28px; margin-bottom: 26px; }

.new2 .newsx p { color: #666666; }

.new2 .newsx span { color: #666666; }

.new2 .newsx li { color: #666666; }

.new2 .newsx strong { color: #666666; }

.new2 .newsx img { display: block; max-width: 100%; margin: 0 auto; }

.new2 .newsx a { color: #0087d3; }

.new2 .newsx table { width: 90%; border-collapse: collapse; line-height: 22px; border: 1px solid #ccc; margin-left: auto; margin-right: auto; }

.new2 .newsx table tr td { padding: 3px; border: 1px solid #ccc; background: #fff; }

.new2 .updown { padding-top: 15px; border-top: 1px solid #d2d2d2; margin-bottom: 64px; }

.new2 .updown .up { margin-top: 18px; margin-bottom: 6px; }

.new2 .updown p { display: inline-block; text-transform: uppercase; line-height: 28px; font-size: 14px; font-size: 1.4rem; color: #666666; }

.new2 .updown p .img { display: inline-block; }

.new2 .updown p .img img { display: inline-block; position: relative; top: 7px; padding-right: 4px; }

.new2 .updown p .on { color: #0087d3; }

.new2 .updown p a { font-size: 14px; font-size: 1.4rem; color: #333; text-transform: none; }

.new2 .updown p:hover a { color: #0087d3; }

.new2 .new2_right .back { display: block; font-size: 18px; font-size: 1.8rem; max-width: 222px; width: 100%; margin: 0 auto; height: 51px; line-height: 51px; text-align: center; color: #fff; background: #0087d3; margin-bottom: 35px; border-radius: 10px; }

.new2 .new2_right .title { padding-top: 14px; margin-bottom: 9px; display: block; font-size: 24px; font-size: 2.4rem; color: #0087d3; line-height: 40px; font-weight: bold; }

.new2 .new2_right .inpu { text-align: center; margin-bottom: 24px; }

.new2 .new2_right .inpu a { display: inline-block; width: 130px; height: 38px; line-height: 38px; text-align: center; margin-left: 25px; margin-right: 25px; font-size: 14px; font-size: 1.4rem; color: #0087d3; border-radius: 50px; border: 1px solid #0087d3; }

.new2 .new2_right .inpu a:hover { background: #0087d3; color: #fff; }

.new2 .new2_right .ex_wrap { border: 1px solid #b3d3ec; width: 353px; background: #fff; margin: 0 auto; padding-bottom: 40px; }

.new2 .new2_right .ex_wrap dl { max-width: 319px; width: 100%; margin: 0 auto; margin-bottom: 17px; }

.new2 .new2_right .ex_wrap dl { display: block; }

.new2 .new2_right .ex_wrap a { display: block; }

.new2 .new2_right .ex_wrap dl a.we_tit { margin-top: 14px; font-size: 16px; font-size: 1.6rem; font-weight: bold; color: #333; line-height: 24px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

.new2 .new2_right .ex_wrap dl .titlewen { line-height: 30px; color: #999999; }

.new2 .new2_right .ex_wrap img { display: block; max-width: 319px; max-height: 209px; width: 100%; }

.new2 .new2_right .ex_wrap .top_imgtd { display: block; width: 8px; height: 20px; margin-bottom: 30px; }

.new2 .new2_right .share1 { position: relative; display: block; width: 240px; margin: 0 auto; margin-bottom: 46px; }

.new2 .new2_right .share1 .span4 { font-size: 16px; font-size: 1.6rem; color: #0087d3; height: 26px; line-height: 26px; }

.new2 .new2_right .at-resp-share-element .at-icon { width: 26px !important; height: 26px !important; }

.new2 .new2_right .at-style-responsive .at-share-btn { padding: 0 !important; border-radius: 2px !important; }

.new2 .new2_right .at-resp-share-element .at-share-btn .at-icon-wrapper { width: 26px !important; height: 26px !important; }

.new2 .new2_right .at-resp-share-element .at-share-btn { margin-bottom: 0 !important; margin-right: 3px !important; }

@media screen and (max-width: 1219px) { .new2 .new2_left { width: 605px; } }

@media screen and (max-width: 999px) { .new2 .new2_left { width: 545px; } }

@media screen and (max-width: 979px) { .new2 .new2_right .share1 { margin-bottom: 15px; }
  .new2 .new2_right { width: 100%; float: none; }
  .new2 .new2_left { width: 100%; float: none; }
  .new2 .new2_right .ex_wrap { max-width: 100%; width: 100%; }
  .new2 .new2_right .ex_wrap dl { max-width: 90%; width: 90%; } }

@media screen and (max-width: 400px) { .new2 .new2_right .ex_wrap dl { width: 90%; }
  .new2 .ex_wrap { width: 100%; }
  .new2 .ex_wrap dl { width: 90%; } }

.technical { padding-bottom: 100px; }

.technical .tec_l { width: 826px; }

.technical .tec_l .te_wrap { border-top: 1px solid #cccccc; margin-bottom: 50px; }

.technical .tec_l p { color: #666666; font-size: 14px; font-size: 1.4rem; line-height: 30px; border-bottom: 1px solid #cccccc; padding-left: 26px; background: url(../images/tec_l.png) no-repeat left 19px; font-weight: bold; padding-bottom: 10px; padding-top: 10px; }

.technical .tec_l p a { color: #666666; }

.technical .tec_r { width: 321px; background: #eeeeee; }

.technical .tec_r .title2 { height: 48px; line-height: 48px; background: #0087d3; color: #fff; }

.technical .tec_r .title2 a { font-size: 22px; font-size: 2.2rem; color: #fff; padding-left: 16px; font-weight: bold; text-transform: uppercase; }

.technical .tec_r_ul { max-width: 262px; margin: 0 auto; }

.technical .tec_r_ul li { margin-top: 21px; }

.technical .tec_r_ul a { display: block; margin: 0 auto; }

.technical .tec_r_ul img { display: block; margin: 0 auto; border: 1px solid #cccccc; }

.technical .tec_r_ul span { margin-top: 18px; padding-bottom: 8px; display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; text-align: center; font-size: 16px; font-size: 1.6rem; line-height: 30px; }

@media screen and (max-width: 1219px) { .technical .tec_l { width: 68%; }
  .technical .tec_r { width: 30%; } }

@media screen and (max-width: 999px) { .technical .tec_l { width: 100%; float: none; margin-bottom: 40px; }
  .technical .tec_r { width: 100%; float: none; } }

/*==========sample==================*/
.sample { padding-bottom: 80px; }

.sample .wp { background: url(../images/sample.png) no-repeat left top; }

.sample .semp_l { width: 670px; margin-left: 81px; padding-top: 34px; }

.sample .tile3 { font-size: 14px; font-size: 1.4rem; line-height: 30px; margin-bottom: 10px; }

.sample .title4 { font-size: 14px; font-size: 1.4rem; line-height: 36px; }

.sample .title4 span { display: block; font-weight: bold; padding-left: 30px; background: url(../images/semp_lli1.png) no-repeat left 15px; }

.sample .title5 { margin-top: 15px; }

.sample .title5 p { font-size: 14px; font-size: 1.4rem; line-height: 26px; margin-bottom: 29px; }

.sample .title5 span { color: #0087d3; }

.sample .semp_r { width: 347px; }

.sample .semp_r p { color: #0087d3; text-transform: uppercase; font-size: 48px; font-size: 4.8rem; font-weight: bold; line-height: 64px; }

.sample .semp_r span { color: #333; display: block; padding-left: 85px; }

.sample .spam { display: none; margin: 0 auto; }

.sample .coxin { max-width: 890px; width: 100%; padding-top: 75px; }

.sample .coxin .int1 { border: 1px solid #d2d2d2; width: 403px; height: 48px; padding-left: 24px; margin-bottom: 21px; float: left; font-size: 14px; font-size: 1.4rem; line-height: 48px; font-family: Verdana; }

.sample .coxin .int2 { border: 1px solid #d2d2d2; width: 403px; height: 48px; padding-left: 24px; margin-bottom: 21px; float: right; font-size: 14px; font-size: 1.4rem; line-height: 48px; }

.sample .coxin textarea { font-family: Verdana; border: 1px solid #d2d2d2; width: 863px; height: 122px; background: url(../images/sapnxing.png) no-repeat left top; padding-left: 24px; margin-bottom: 21px; float: left; font-size: 14px; font-size: 1.4rem; line-height: 48px; }

.sample .coxin .int3 { border: 1px solid #d2d2d2; width: 221px; height: 48px; padding-left: 24px; margin-bottom: 21px; float: left; font-size: 14px; font-size: 1.4rem; line-height: 48px; }

.sample .coyanzheng { float: left; margin-bottom: 21px; margin-left: 14px; cursor: pointer; }

.sample .coxin .int4 { font-family: Verdana; width: 428px; height: 48px; float: right; margin-bottom: 21px; color: #fff; font-size: 14px; font-size: 1.4rem; background: #0087d3; cursor: pointer; }

.sample .coxin .xing { background: url(../images/sapnxing.png) no-repeat left top; }

@media screen and (max-width: 1219px) { .sample .con_l { width: 56%; }
  .sample .con_r { width: 42%; }
  .sample .coxin .int1 { width: calc( 48% - 24px); }
  .sample .coxin .int2 { width: calc( 48% - 24px); }
  .sample .coxin textarea { width: calc( 100% - 24px); }
  .sample .coyanzheng { margin-left: 11px; }
  .sample .wp { background: none; }
  .sample .semp_l { float: none; max-width: 817px; width: 100%; margin: 0px auto; padding: 59px; box-sizing: border-box; background: url(../images/sample.png) no-repeat left top; }
  .sample .semp_r { float: none; max-width: 817px; width: 100%; margin: 0px auto; }
  .sample .spam { display: block; margin: 0 auto; }
  .sample 　 .coxin .int4 { width: 281px; } }

@media screen and (max-width: 999px) { .sample .semp_l { float: none; max-width: 817px; background: none; padding: 0px; }
  .sample .con_l { width: 100%; float: none; }
  .sample .con_r { width: 100%; float: none; margin-bottom: 20px; } }

@media screen and (max-width: 768px) { .sample .semp_r p { font-size: 30px; font-size: 3rem; line-height: 36px; }
  .sample .coxin .int1 { width: calc( 100% - 24px); margin-bottom: 10px; }
  .sample .coxin .int2 { width: calc( 100% - 24px); margin-bottom: 10px; }
  .sample .coxin textarea { margin-bottom: 10px; height: 120px; }
  .sample .coxin .int3 { width: calc( 100% - 24px); margin-bottom: 10px; }
  .sample .coyanzheng { margin-left: 0px; margin-bottom: 10px; }
  .sample .coxin .int4 { width: 100%; margin-bottom: 20px; } }
