@charset "UTF-8";
//公共变量
$width:1200px; 
$color: #0087d3;

// .wap-hd-nav start
.wap-hd-nav{
    display: none;
}

.wap-nav {
    width: 5px;
    height: 100%;
    position: absolute;
    right: 0;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    z-index: 999999999999999;
    cursor: pointer;
    position: fixed;
    top: -5px;
    right: 0;
    display: block;
    display: none\0;
}

.wap-nav .nav {
    overflow: hidden;
    width: 300px;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: auto;
    overflow: scroll;

    span{
        font-family: arial, helvetica, sans-serif;
    }
}

.wap-nav .nav form {
    margin: 15px auto;
    width: 258px;
    height: 26px;
    border: 1px solid #fff;
}

.wap-nav .nav form .iptc {
    box-sizing: border-box;
    padding-left: 15px;
    background-color: #fff;
    width: 80%;
    height: 26px;
    line-height: 26px;
    display: block;
    float: left;
}

.wap-nav .nav form .ipsc {
    width: 20%;
    height: 26px;
    line-height: 26px;
    display: block;
    float: right;
    background: #fff url('../images/sch2.png') no-repeat center center;
}

.wap-nav .nav>ul {
    display: none;
    margin-top: 0px;
    margin-bottom: 0px;
    overflow: scroll;
    padding-top: 45px;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.wap-nav .nav>ul>li {
    line-height: 40px;
    font-size: 12px;
    font-size: 1.2rem;
    text-indent: 20px;
    position: relative;
}

.wap-nav .nav>ul>li .addition {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 10px;
    background: url("../images/shizi.png") no-repeat center center;
}

.wap-nav .nav>ul>li .addition.fa:before {
    margin-right: 5px;
    color: #fff;
    text-indent: 0;
}

.wap-nav .nav>ul>li>a {
    margin-right: 40px;
    color: #fff;
    font-size: 12px;
    font-size: 1.2rem;
    display: block;
}

.wap-nav .nav>ul>li>a span {
    color: #fff;
    font-size: 12px;
    font-size: 1.2rem;
}

.wap-nav .nav>ul>li .c-show {
    display: none;
    width: 100%;
}

.wap-nav .nav>ul>li .c-show1 .c-s1 {
    position: relative;
    padding-left: 15px;
}

.wap-nav .nav>ul>li .c-show1 .c-s1>a {
    position: relative;
    margin-right: 40px;
    font-size: 12px;
    font-size: 1.2rem;
    color: #fff;
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 30px;
}

.wap-nav .nav>ul>li .c-show1 .c-s1>a span {
    font-size: 12px;
    font-size: 1.2rem;
    color: #fff;
}

.wap-nav .nav>ul>li .c-show1 .c-s1>.c-show2 {
    position: relative;
}

.wap-nav .nav>ul>li .c-show1 .c-s1>.c-show2 .c-s2 {
    position: relative;
}

.wap-nav .nav>ul>li .c-show1 .c-s1>.c-show2 .c-s2>a {
    margin-right: 40px;
    text-indent: 35px;
    font-size: 12px;
    font-size: 1.2rem;
    color: #fff;
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 30px;
}

.wap-nav .nav>ul>li .c-show1 .c-s1>.c-show2 .c-s2>a span {
    font-size: 12px;
    font-size: 1.2rem;
    color: #fff;
}

.wap-nav .nav>ul>li .c-show1 .c-s1 .c-s2 .c-show3 {
    position: relative;
}

.wap-nav .nav>ul>li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a {
    margin-right: 40px;
    text-indent: 50px;
    font-size: 12px;
    font-size: 1.2rem;
    color: #fff;
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 30px;
}

.wap-nav .nav>ul>li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a span {
    font-size: 12px;
    font-size: 1.2rem;
    color: #fff;
}



.wap-nav .nav>ul>dl {
    padding: 20px;
}

.wap-nav .nav>ul>dl dd {
    float: left;
    margin: 0 2px;
}

.wap-nav .menubtn {
    width: 23px;
    height: 18px;
    position: absolute;
    top: 12px;
    right: 12px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    z-index: 9999;
    display: none\0;
}

.wap-nav .menubtn span {
    display: block;
    width: 100%;
    height: 3px;
    margin-bottom: 3px;
    background-color: $color;
}

@media screen and (min-width: 960px){
    .wap-nav, .menubtn{
        display: none;
    }
}

@media screen and (max-width: 959px){
    
    .wap-hd-nav{
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        background-color: $color;
    }

    .wap-hd-nav-item{
        width: 25%;
        
        a{
            display: block;
            padding: 5px 0;
            line-height: 20px;
            font-size: 14px;
            color: white;
            text-align: center;
            background-color: #363636;

            &.active{
                color: white;
                background-color: $color;
            }
        }
    }
}
    // .wap-hd-nav end