@charset "UTF-8";
//公共变量
$width:1200px; 
$color: #0087d3;
  
  

@mixin float($value:left){
    float: $value;
}

@mixin margin($top:0,$right:auto,$bottom:0,$left:auto){
    margin: {
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
    };
}

@mixin transition($type:all,$time:350ms){
    transition: $type $time;
}

@mixin font-size($value:16){
    font-size: $value + 0px;
    font-size: $value / 10 + 0rem;
}

@mixin placeholder($color:#333){  
    &::-webkit-input-placeholder{
        color:$color;
    }
    &::-moz-placeholder{  
        color:$color;
    }
    &:-moz-placeholder{    
        color:$color;
    }
    &:-ms-input-placeholder{  
        color:$color;
    }
}

@mixin inline-block(){
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

@function get_w($w, $max: 1200, $b: 100){
    @return $w / $max * $b + 0%;
}

//公共样式文件
* {
    margin: 0px;
    padding: 0px;
}

html{
    font-size: 62.5%;
    height: 100%; 
    
}

body {
    width: 100%;
    overflow-x: hidden;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size:14px;font-size:1.4rem;
    color: black;
}

a {
    color: #333;
    text-decoration: none;
    outline: none;
    tap-highlight-color: transparent;
    focus-ring-color: transparent;
    -webkit-tap-highlight-color: transparent;
    -webkit-focus-ring-color: transparent;
    -moz-tap-highlight-color: transparent;
    -moz-focus-ring-color: transparent;
    @include transition;

    &:hover{
        color: $color;
    }
}

ol, ul, li {
    list-style: none;
}

img {
    max-width: 100%;
    border: 0px solid #ccc;
}

table, embed, video {
    max-width: 100%;
}

input, textarea{
    border: none;
    background: none;
	font-family: inherit;
    
    &[type="submit"]{
        cursor: pointer;
    }
    
    &[type="checkbox"]{
        cursor: pointer;
    }
    
    &[type="radio"]{
        cursor: pointer;
    }
    
    &:focus{
        outline: none;
    }
}

textarea{
    resize: none;
}

button{
    border: none;
    background: none;
    cursor: pointer;
}

em,i{
    font-style: normal;
}
    
/* .c-xx start */
.c-fl{
    @include float;
}

.c-fr{
    @include float(right);
}

.c-cb{
    clear: both;
}

.c-mg{
    @include margin;
}

.c-oh{
    overflow: hidden;
}

.c-wd{
    width: $width;
}


.c-tsn{
    @include transition;
}

.c-bbx{
    box-sizing: border-box;
}

.c-pr{
    position: relative;
} 

@for $i from 5 through 50 { 
    @if($i % 5 == 0){
        .c-mt-#{$i} { 
            margin-top: $i + 0px; 
        }             
    }
}

.c-nomt{
    margin: {
        top: 0 !important;
    };
}

.c-nomr{
    margin: {
        right: 0 !important;
    };
}

.c-nomb{
    margin: {
        bottom: 0 !important;
    };
}

.c-noml{
    margin: {
        left: 0 !important;
    };
}  

.c-nofont{
    line-height: 0;
    font-size: 0;
} 

.c-nobg{
    background: none !important;
}	

/* .c-xx end */

/* .f-xx start */
.f-czjz {
    position: relative;
    top: 50%;
    transform: translate(0px, -50%);
}  

.f-box{
    margin: 0 auto;
    max-width: $width;
    overflow: hidden;
}


@media screen and (min-width:960px) and (max-width:1219px){
    .f-box{
        width: 950px;
    }
}

@media screen and (min-width: 768px) and (max-width:959px){
    .f-box{
        width: 750px;
    }
}

@media screen and (max-width: 767px){
    .f-box{
        padding: {
            left: 10px;
            right: 10px;
        };
        box-sizing: border-box;
    }
}	
/* .f-xx end */ 

.l {
    float: left;
}

.r {
    float: right;
}

.fix {
    *zoom: 1;
}

.fix:after,
.fix:before {
    display: block;
    content: "clear";
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
} 
 
.box {
    position: relative;
}
.mln{ margin-left: 0!important; }
.clear{
    clear: both;
}

.wp {
    width: 1200px;
    margin: auto;
}
.wpp {
    width: 1400px;
    margin: auto;
}
@media screen and (min-width:1000px) and (max-width:1419px) {
 
    .wpp {
        width: 980px;
        margin: auto;
    }
}
@media screen and (min-width:1000px) and (max-width:1219px) {
    .wp {
        width: 980px;
        margin: auto;
    } 
}

 

@media screen and (max-width:999px) {
    .wp {
        width: 95%;
        margin: auto;
    }
    .wpp {
        width: 95%;
        margin: auto;
    }
}






/* .m-xx start */
.m-desc{
    font-size:14px;
    font-size:1.4rem;
    color:#333; 
    line-height: 30px;

    a{
        color: $color;
        
        &:hover{
            text-decoration: underline;
        }
    }
    
    p{ 
        font-size:14px;
        font-size:1.4rem;
        color:#333; 
        line-height: 30px; 
    }
    
    span{ 
        font-size:14px;
        font-size:1.4rem;
        color:#333; 
        line-height: 30px; 
    }
    
    b{ 
        font-size:22px;
        font-size:2.2rem;
        color:#333; 
        line-height: 30px; 
    }
    
    a{ 
        font-size:14px;
        font-size:1.4rem;
        color:$color; 
        line-height: 30px; 
    }
    
    img{ 
        display: block; 
        max-width: 100%; 
        margin: 0 auto;
    }
    
    iframe{ 
        display: block; 
        max-width: 100%; 
        margin: 0 auto;
    }
    
    table{ 
        width:90%; 
        border-collapse:collapse; 
        line-height:22px; 
        border:1px solid #ccc; 
        margin-left:auto; 
        margin-right:auto;
    }
    
    table tr td{ 
        padding:3px; 
        border:1px solid #ccc; 
        background:#fff;
        font-size:14px;
        font-size:1.4rem;
    } 
        
}    

@media screen and (max-width: 767px){
    .m-tt1{
        font-size: 24px !important;
        font-size: 2.4rem !important;
    }

    .m-tt2{
        font-size: 20px !important;
        font-size: 2rem !important;
    }

    .m-tt3{
        font-size: 18px !important;
        font-size: 1.8rem !important;
    }

    .m-desc1{
        font-size: 16px !important;
        font-size: 1.6rem !important;
    }

    .m-desc2{
        font-size: 14px !important;
        font-size: 1.4rem !important;
    }

    [class*="-item"]{    
        .item-title{
            font-size: 14px;
            font-size: 1.4rem;
        }
    
        .item-desc{
            font-size: 13px;
            font-size: 1.3rem;
        }

        .item-link{
            font-size: 13px;
            font-size: 1.3rem;
        }

        .item-modal{
            display: none;
        }
    }    
}
/* .m-xx end */

/* .j-xx start */
.j-offset-top{
    @include transition(all, 750ms);
    transform: translateY(100px);
    opacity: 0;

    &.run{
        transform: translateY(0);
        opacity: 1;
    }
}

.j-offset-left{
    @include transition(all, 750ms);
    transform: translateX(100px);
    opacity: 0;

    &.run{
        transform: translateX(0);
        opacity: 1;
    }
}

.j-offset-right{
    @include transition(all, 750ms);
    transform: translateX(-100px);
    opacity: 0;

    &.run{
        transform: translateX(0);
        opacity: 1;
    }
}
 
/* .j-xx end */

[class*="-item"]{
    
    .item-pic{
        position: relative;
        display: block;
        line-height: 0;
        font-size: 0;
        text-align: center;
        overflow: hidden;

        img{
            @include transition;
        }

        &:hover{
            img{
                transform: scale(1.1);
            }
        }
    }

    .item-title{
        overflow: hidden;

        a{
            &:hover{
                color: $color;
            }
        }
    }

    .item-desc{
        overflow: hidden;

        a{
            display: block;
            &:hover{
                color: $color;
            }
        }
    }
}

// .m-crm start
.m-crm{
    margin-top: 18px;
    line-height: 30px;
    @include font-size(14);
    color: #333;
    margin-bottom: 40px;

    .wp{ 
        padding-left: 25px; background: url(../images/crm.png) no-repeat left 10px; 
        border-bottom: 1px solid #cccccc; padding-bottom: 4px; box-sizing: border-box;
    }
    a{
        color: #333;

        &:hover{
            color: $color;
        }

        &.active{
            color: $color;
        }
    }

    @media screen and (max-width: 767px){
        margin-bottom: 20px;
    }

}
// .m-crm end

 
//.m-page start
.m-page{
    
    letter-spacing: 0;
    font-size: 0;
    text-align: center;
    
    a{
        margin: 0 5px;
        @include inline-block();
        width: 35px;
        height: 30px;
        line-height: 28px;
        @include font-size(14);
        color: #333;
        text-align: center;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        @include transition;
        
        &.big{
            width: 75px;
        }
        
        &:hover{
            color: white;
            border-color: $color;
            background-color: $color;
        }
        
        &.active{
            color: white;
            border-color: $color;
            background-color: $color;
        }
    }
    span{display: inline-block;width: 50px;text-align: center;font-size:14px;font-size:1.4rem;color: #4b4b4b;position: relative;top: 3px;}
    img{display: inline-block;position: relative;top: 5px;margin-left: 60px;margin-right: 8px;}

    .ins{margin-left: 10px; width: 170px;display: inline-block;height: 32px; line-height: 30px;text-align: center;
        border: none; outline: none;background-color: $color; color: #fff;font-size: 14px;font-size: 1.4rem;cursor: pointer; }


    @media screen and (max-width: 1219px){
        img{ margin-left: 10px; }
        .ins{ width: 140px; }
    }

    @media screen and (max-width: 767px){
        img,span{ display: none; }
    }

}

.m-page2{
    position: relative;
}

@media screen and (max-width: 767px){
    .m-page{
        margin-top: 25px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        
        a{
            margin: {
                left: 5px;
                right: 5px;
                bottom: 10px;
            };
        }
    }  
    
    .m-page2{
    }
}
//.m-page end

// .m-kf start
 
/**********************  ce  ************************/
.ce{position: fixed;top: 20%;z-index: 99999;width: 69px;background: #f8f8f8;right: 0px; padding: 10px 0px; border-radius: 50px;  }
.ce li{ margin: 5px 10px;border-bottom: 1px solid #aaaaaa; padding-bottom: 5px; text-align: center;}
.ce li.noborder{border: none;}
.ce li span{display: block;margin-top: 1px;font-size: 12px;font-size: 1.2rem;}
.ce li img{margin-top: 6px;}
.ce li:hover span{color: $color;}

.weixin{position: relative;}
.ewm{position: absolute;right: 100%;bottom: -99999px;max-width: 100px; max-height: 100px; }
.weixin:hover .ewm{bottom: 0;}

@media screen and (max-width:767px) {
    .ce{bottom: 0;top: auto;width: 100%;padding: 0;border-radius: 0;right: 0;}
    .ce li{border: none;float: left;width: 25%;margin: 0;}
    body{padding-bottom: 60px;}
    .ewm{right: auto;left: 0;}
    .weixin:hover .ewm{bottom: 100%;}
    .ce li.noborder img{margin-top: 13px;}
}




// .m-kf end
// .returntop start
.returntop{
    position: fixed;
    *position: absolute;
    z-index: 9;
    right: 6px;
    bottom: 30px;
    width: 72px;
    height: 72px;
    background: url('../images/gotop.png') no-repeat center; 
    line-height: 0;
    font-size: 0;
    display: none;
      
}

@media screen and (max-width: 959px){
    .returntop{
        right: 0;
        bottom: 65px;
        width: 36px;
        height: 36px;
        background-size: contain;
    }
}
// .returntop end
@import './nav.scss'; 
  

/* header_top */ 
.header_top{ margin-bottom: 15px;  text-align: right;   margin-top: 12px;
    a{ display: inline-block;  
        line-height: 30px;   
        color: #666666; 
    }
    span{ display: inline-block;  
        line-height: 30px; 
        padding-left: 25px;   
        color: #666666;
        margin-top: 2px;
    } 
    .header_form{  
        margin-top: 5px;
        border: 1px solid #333333;
        width: 209px; 
        height: 22px; 
        line-height: 22px;
        border-radius: 50px;
        position: relative;   
        margin-left: 28px;
        .tex{ 
            background: transparent; 
            border-radius: 50px;  
            text-indent: 10px; 
            width: 100% ; 
            height: 22px; 
            line-height: 22px; 
            display: inline-block;
            color: #999999;
            font-style: italic;
            font-size:14px;
            font-size:1.4rem;  }
        .sub{ 
            position: absolute; 
            top:0px; right: 0px;
            height: 34px; width: 30px; font-size: 0px; cursor: pointer; z-index: 99; 
            background: url( ../images/suosou.png )  no-repeat left -1px; }
    }

    .shejiao {
        a{ margin-left: 16px; }
    }
    
}   
 

/* header */ 
.header {  background: url(../images/header.png) no-repeat center top; 
    .logo{ margin-top: 0px; }
    .logo img{ display: block; max-width: 200px; max-height: 177px; width: 100%; margin: 0 auto; }
 

}
.header_ul1 { 
    > li{ float: left; margin-left: 35px; margin-right: 0px; position: relative; 
        &:hover{
            .dan_header { display: block; }
        } 
    }
    > li>a{ color: #333; line-height: 38px; display: block; font-weight: bold; text-transform: uppercase; padding-bottom: 15px; }
    > li:hover>a, li.on>a{ color: $color; background: url(../images/hewli.png) no-repeat center bottom;  }  
    
}

.dan_header {   display: none; width: 171px;  position: absolute; left: 0px; top: 100%; background: #fff;  z-index: 999999;   
    > ul { border: 1px solid #cccccc;
        > li{ line-height: 36px;   position: relative; left: 0px; }
        > li>a{ padding-left: 37px; position: relative; font-size:14px;font-size:1.4rem; color:#666666; line-height: 36px; display: block;}
        > li:hover>a{ color: $color;  background: url(../images/hewli2.png) no-repeat 11px 15px; }
        > li:hover .dan_sanheader{ display: block;  }  
    } 
} 
 

.dan_sanheader { display: none; width: 297px; position: absolute; left: 100%; top: 0px;  z-index: 999999;  border-left: 2px solid transparent;
    > ul { border: 1px solid #cccccc;background: #fff;
        > li{ line-height: 36px;   position: relative; left: 0px; }
        > li>a{ padding-left: 37px; position: relative; font-size:14px;font-size:1.4rem; color:#666666; line-height: 36px; display: block;}
        > li:hover>a{ color: $color;  background: url(../images/hewli2.png) no-repeat 11px 15px; }
        > li:hover .dan_sanheader2{ display: block;  }  
    }   
}

.dan_sanheader2 { display: none; width: 120px; position: absolute; left: 100%; top: 0px; z-index: 999999; border-left: 2px solid transparent;
    > ul { border: 1px solid #cccccc;background: #fff; 
        > li{ line-height: 36px;   position: relative; left: 0px; }
        > li>a{ padding-left: 37px; position: relative; font-size:14px;font-size:1.4rem; color:#666666; line-height: 36px; display: block;}
        > li:hover>a{ color: $color;   } 
    }   
}




@media screen and (max-width:1419px) { 
    .header_ul1 > li { margin-left: 20px; } 
    .header .logo { width: 100px; }
    .header_top .header_form{ width: 130px; }
}


@media screen and (max-width:1219px) { 
    .header_ul1 > li { margin-left: 10px;   } 
}

@media screen and (max-width:999px) {
    .header{ position: relative;  } 
    .header_ul1 > li > a { font-size:14px;font-size:1.4rem; }
    .header_top span{ font-size: 12px; font-size: 1.2rem; }
    
}

@media screen and (max-width:959px) { 
    .header{ background: none; }
    .header_top .header_form { display: none; }
    .header_top {  display: none; }
    .header_ul1 { display: none; }
    .header .logo { float: none; margin: 0 auto; width: 100%; max-width: 200px; margin-top: 10px; margin-bottom: 10px; }
}


@media screen and (max-width:768px) { 
    .slide .carousel-control{ display: none;  }

}
 
  

/* footer */
.footer{ background:#ececec;  padding-top: 45px; border-top: 1px solid #d7d7d7; padding-bottom: 37px;  } 
.footer a:hover { color: $color; }
.footer .title{ font-size:24px;font-size:2.4rem; color:#333;  line-height:30px; text-transform: uppercase; font-weight: bold; }
.footer .title a{ color:#333;   } 

.footer_left { float: left; width: 322px;  } 
.footer_center {float: left; width: 271px; }
.footer_right { float: right; width:494px; }
 
.ftx_ul{  margin-top: 22px;
    li{ line-height: 36px; color: #333333;  padding-left: 30px;   a{ color: #333333;  }  } 
    li.li1{ background: url(../images/foli1.png) no-repeat left 10px;   }
    li.li2{ background: url(../images/foli2.png) no-repeat left 10px; }
    li.li3{ background: url(../images/foli3.png) no-repeat left 10px;  }
    li.li4{ background: url(../images/foli4.png) no-repeat left 10px;   }
    li.li5{ background: url(../images/foli5.png) no-repeat left 10px;  }
    li.li6{ background: url(../images/foli6.png) no-repeat left 10px;   }
}
 
 
.ftx_ul2{ margin-top: 22px; 
    li { line-height: 36px; color: #333333; }
    li a{ padding-left: 20px; background: url(../images/fibgj.png) no-repeat left 4px;  color: #333333;  } 
}


.footer_right {
    .title{ padding-left: 20px; }
    form { margin-top: 34px;}
    .c_div { margin-top: 0px; margin-bottom: 10px; }
    .c_div2 { margin-top: 10px; }
    .c_nr { width: 224px; padding-left: 20px; }
    .c_nr2 { padding-left: 20px; }
    .xing { background: url(../images/xing.png) no-repeat left 19px; }
    .c_div_l { padding-left: 20px; background: url(../images/xing.png) no-repeat left 19px; }
    .c_input,.c_captcha { font-size: 14px; font-size: 1.4rem; color: #999999; height: 42px; line-height: 42px; border: 1px solid #cccccc; }
    .c-span { width: 18px; display: block; height: 42px; line-height: 42px; font-size: 14px; font-size: 1.4rem; color: #999999; text-indent: 1px; }
    .c_input { width: 190px; padding-left: 35px; display: block; background-color: #fff; }
    .c_textarea { font-size: 14px; font-size: 1.4rem; color: #999999; font-family: Arial; line-height: 42px; border: 1px solid #cccccc;
        background: #fff url(../images/xp-icon5.png) no-repeat left top; padding-left: 35px; width: 93%; height: 124px; }
    .c_yzm { display: block; float: left; cursor: pointer; }
    .c_captcha { display: block; float: left; padding-left: 15px; width: 155px; margin-right: 11px; background: #fff; }
    .c_send { float: right; width: 161px;  background: $color; color: #fff; height: 42px; 
        text-transform: uppercase; line-height: 36px; cursor: pointer; transition: 0.5s; -moz-transition: 0.5s; -o-transition: 0.5s; -webkit-transition: 0.5s; }
    .c_send:hover { background: #aaa; }
    .c_xpbg1 .c_input { display: block; background: #fff url(../images/xp-icon1.png) no-repeat left top; }
    .c_xpbg2 .c_input { display: block; background: #fff url(../images/xp-icon2.png) no-repeat left top; } 

}
.shejiao a img{ -webkit-transition: 0.3s all;-moz-transition: 0.3s all;-ms-transition: 0.3s all;transition: 0.3s all; }
.shejiao a:hover img{ -webkit-transform: rotate(360deg);-moz-transform: rotate(360deg);-ms-transform: rotate(360deg);transform: rotate(360deg);}


.footer_bot {  padding-top: 35px; border-top: 1px solid #d7d7d7; color: #333;  text-align: center; background: #f8f7f7; padding-bottom: 20px;
    a:hover { color: $color; }
    .shejiao{   display: inline-block;   }
    .shejiao a{ display: block; margin-left: 11px; float: left; margin-bottom: 22px; } 
 
    p { color: #333; line-height: 30px; } 
    p a { display: inline-block; padding-left: 2px;line-height: 30px;color: #333; } 
    .Links {  height: 30px;  line-height: 30px; color: #333; } 
    .Links a { display: inline-block; padding-right: 25px; color: #333; }
    
    
}


@media screen and (max-width: 1419px) {
    .footer_left {  width: 49%;  margin: 0 auto; } 
    .footer_center {  width: 49%;;margin: 0 auto; }
    .footer_right { float: right; width:49%; margin: 0 auto;}
    .footer .title {  margin-top: 20px; }

    .footer_right .c_div{ margin-bottom: 0px; }
    .footer_right .c_nr{ width: calc( 100% - 20px); }
    .footer_right .c_input{ width: calc( 100% - 35px); margin-bottom: 20px; }
    .footer_right .c_textarea{ width: calc( 100% - 35px); margin-bottom: 20px; }
    .footer_right .c_captcha{ width: calc( 100% - 15px); margin-bottom: 20px; }

}
 

@media screen and (max-width: 999px) { 
    
    .footer_left { width: 33.33%; float: left; box-sizing: border-box;  }
    .footer_center { width: 33.33%; float: left; margin-left: 0px;box-sizing: border-box;  }
    .footer_right { width: 100%;box-sizing: border-box; margin-top: 20px;  } 

    .ftx_ul li{ font-size:12px;font-size:1.2rem; }
    .ftx_ul2 li { font-size:12px;font-size:1.2rem; }
}


@media screen and (max-width: 768px) { 
  .footer { display: none; }
  .footer_left, .footer_right, .footer_center  { display: none; } 
  .Links { display: none; } 
  .footer_bot .shejiao a { margin-left: 4px; }
  .footer_bot {  border: 0px; }
  .footer_bot p{ float: none; }
  .footer .shejiao{ float: none; margin: 0 auto; }
  .footer .shejiao a{ margin-left: 0px; margin-top: 10px; margin-right: 20px; }
}
 

// main_title
.main_title{ padding-top: 59px; text-align: center;

    a{ display: inline-block; padding: 0px 154px; background: url(../images/main_tit.png) no-repeat left center, url(../images/main_tit.png) no-repeat right center; font-size:40px;font-size:4rem; font-weight: bold; text-transform: uppercase; }
    span{ color: $color; }


    @media screen and (max-width:999px) {
        a{  font-size:30px;font-size:3rem; }
    }


    @media screen and (max-width:768px) {
        padding-top: 40px;
        a{ padding: 0px; background: none; font-size:28px;font-size:2.8rem; }
    }


}

// main_p
.main_p{ max-width: 961px; margin: 0 auto;  text-align: center;    margin-top: 34px;
    a{ display: block;  line-height: 30px; max-height: 60px; overflow: hidden;  }

    @media screen and (max-width:768px) {
        margin-top: 10px;
    }
}



 
/*==========main1==================*/
.main1{  background: url(../images/main1.png) no-repeat center top;padding-bottom: 95px; 
    .img-scroll { position:relative; width: 100%; margin-top: 40px;  
        .prev{position:absolute;display:block;width:18px;height:32px; top: 50%; margin-top:-32px;z-index: 9;}
        .next{position:absolute;display:block;width:18px;height:32px; top: 50%; margin-top:-32px;z-index: 9;}
        .prev { left:0;cursor:pointer; background:url(../images/arrowleft.png) no-repeat center;}
        .next { right:0;cursor:pointer; background:url(../images/arrowright.png) no-repeat center;} 
        .prev:hover { background:url(../images/arrowlefton.png) no-repeat center;} 
        .next:hover { background:url(../images/arrowrighton.png) no-repeat center;} 
    }
 
    .img-list { position:relative; width:93%; overflow:hidden;margin: 0 auto;
        ul { width:1000%;}
         li { float:left;  width:254px; margin-right:33px; 
            img{ display: block; max-width:254px; max-height:233px;width: 100%; margin: 0 auto;  border:1px solid #cccccc;  box-sizing: border-box; }
            .m4zi{ display: block; margin: 0 auto; max-width:254px; width: 100%; 
                font-size:16px;font-size:1.6rem; margin-top: 10px;
                color: #333;line-height: 38px; text-align: center;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;}
            .m3lien{ max-width:254px;text-align: center; position: relative; margin: 0 auto; overflow: hidden; }
         
        } 
    }




 
    @media screen and (max-width:1219px) {
       .img-list { width: 89%; }
       .img-list li {  width: 192px; }
    }
    
    @media screen and (max-width:999px) {  
       .img-list li{ width: 3%; margin-right: .5%; }
    
    }
    
    @media screen and (max-width:768px) {
        padding-bottom: 50px;
       .img-scroll { margin-top: 20px; } 
       .img-list li { width: 4.5%; margin-right: 1%; }
       .img-list li .m4zi{ font-size:14px;font-size:1.4rem; }
    }
    @media screen and (max-width:400px) {  
       .img-list li { width: 10%; margin-right: .487%; }
    }  
}
  
 
/*==========main2==================*/
.main2{ max-width: 1920px; margin: 0 auto; 
    .m2tu{ width:50%; *width:50%; }
    .m2zi{ width:50%; *width:50%;   padding-top: 69px; } 
    .m2tu a{ display:block;}
    .m2tu a img{ display:block; margin:auto; width:100%; max-width:960px; max-height:505px;}
    .m2zin{ width:519px; margin-right:45px; padding-top:0px; }
    .m2biao{ display:block; font-size:40px; font-size:4rem;  text-transform:uppercase; line-height:40px; padding-top:17px;  font-weight: bold; color: $color; }
     .m2zi p a{ display:block; font-size:14px; font-size:1.4rem; line-height:30px; margin-top:30px; max-height: 120px; overflow: hidden; }
    .m2mor{ display: inline-block; margin-top: 92px; text-transform: uppercase; font-weight: bold;   color: #333; padding-right: 31px; background: url( ../images/m2more.png ) no-repeat right center; }
  
    .m2zin2{ margin-left: 45px; }

    @media screen and (max-width:1620px) {
        .m2zi{   padding-top: 5%; } 
        .m2mor{ margin-top: 10%; }
    }

    @media screen and (max-width:1449px) {
        .m2mor{ margin-top: 5%; }
        .m2zi{ padding-top: 3%; }
    }
    @media screen and (max-width:1219px) {
        .m2tu{ width:95%; *width:95%; float: none; margin:20px auto;   }
        .m2zi{ width:95%; *width:95%; float: none; margin:20px auto;  } 

        .m2zin{ float: none; margin: 0 auto; width:95%  }
        .m2zi{ padding-top: 0px; margin-bottom: 30px; }
        .m2mor{ margin-top: 10px; }
        .m2zi p a{ margin-top: 10px; }
    }
    
    @media screen and (max-width:999px) {
    
        .m2biao{ font-size:30px;font-size:3rem; padding-top: 0px; line-break: 30px; }
    }
    
    @media screen and (max-width:768px) {
        .m2biao { font-size:26px;font-size:2.6rem; }
    }  
}
 

/*==========main3==================*/
.main3{  background: url(../images/main3.png) repeat-y left top; padding-bottom: 34px;
 
    .m3_ul{ margin-top: 66px; 
        li{ padding-left: 122px; width: 340px; width: 340px\0;  float: left; box-sizing: border-box; min-height: 110px; margin-bottom: 83px; margin-left: 70px; }
        span { display: block; }
        .titlr{ font-size:16px;font-size:1.6rem; line-height: 30px; color: #333; margin-top: 20px; font-weight: bold; }
        .p{ font-size:14px;font-size:1.4rem;  line-height: 24px;  height: 48px; overflow: hidden; }

        .li1 { background: url(../images/m3tu1.png) no-repeat left top;   }
        .li2 { background: url(../images/m3tu2.png) no-repeat left top;  }
        .li3 { background: url(../images/m3tu3.png) no-repeat left top;  }
        .li4 { background: url(../images/m3tu4.png) no-repeat left top;  }
        .li5 { background: url(../images/m3tu5.png) no-repeat left top;  }
        .li6 { background: url(../images/m3tu6.png) no-repeat left top;  }

        .li1:hover { background: url(../images/m3tu1on.png) no-repeat left top; .titlr{ color: $color; } }
        .li2:hover { background: url(../images/m3tu2on.png) no-repeat left top; .titlr{ color: $color; } }
        .li3:hover { background: url(../images/m3tu3on.png) no-repeat left top; .titlr{ color: $color; } }
        .li4:hover { background: url(../images/m3tu4on.png) no-repeat left top; .titlr{ color: $color; } }
        .li5:hover { background: url(../images/m3tu5on.png) no-repeat left top; .titlr{ color: $color; }  }
        .li6:hover { background: url(../images/m3tu6on.png) no-repeat left top; .titlr{ color: $color; } }
    }
 
  
    @media screen and (max-width:1219px) {
        background: #f6f7f6;
        .m3_ul{ margin-top: 66px; 
            li{ width: 31%; margin-left: 1%!important; margin-right: 1%; }
        }
    }
    
    @media screen and (max-width:768px) {
        .m3_ul{ margin-top: 40px; 
            li{ width: 48%; margin-left: 1%!important; margin-right: 1%; padding-left: 0px; min-height: auto;  }
            .p { height: 48px; overflow: hidden; }
            .li1 { background:none!important; }
            .li2 { background:none!important; }
            .li3 { background:none!important; }
            .li4 { background:none!important; }
            .li5 { background:none!important; }
            .li6 { background:none!important; }

        }
        
    }
    @media screen and (max-width:600px) {
        .m3_ul{ margin-top: 30px; 
            li{ width: 100%; float: none; margin-bottom: 30px; }
        }

    }  
}
  


/*==========main4==================*/
.main4{  padding-top: 77px;
 
    .ttitle{ margin-top: 19px;  margin-bottom: 37px;
        a{font-size:40px;font-size:4rem; font-weight: bold; text-transform: uppercase; } span{ color: $color;  } }
 
    p{ margin-bottom: 34px;
        a{ color: #666666; font-size:14px;font-size:1.4rem; line-height: 30px; max-height: 77px; overflow: hidden; }
    }
 
    .imgg{ display: block; margin-left: 68px; }
    .more{ display: block;  background: url(../images/m4more.png) no-repeat center center;  color: #fff; width: 140px; height: 45px; line-height: 45px;  text-indent: 11px; text-transform: uppercase;  }
 
    @media screen and (max-width:1219px) {
        .imgg{  width: 45%; }
    }
    
    @media screen and (max-width:999px) {
        .imgg{  width: 100%; float: none; margin: 0 auto; 
            img{ display: block; margin: 0 auto;  }
        }
        .ttitle a{ font-size:30px;font-size:3rem; }
    }
    
    @media screen and (max-width:768px) {
        .ttitle { margin-bottom: 10px; }
        .ttitle a { font-size:28px;font-size:2.8rem; }
        p{ margin-bottom: 10px; 
            a{ font-size:14px;font-size:1.4rem; }
        }
    }
    @media screen and (max-width:320px) {
    
    }  
}
  
/*==========main5==================*/
.main5{  background: url(../images/main5.png) no-repeat left top; margin-top: 75px; padding-top: 68px; padding-bottom: 80px;

    a{ color: #fff; text-align: center; } 
    .title { font-size:40px;font-size:4rem; text-align: center;font-weight: bold;  text-transform: uppercase; }
    p{ text-align: center; max-width: 961px; margin: 0 auto; margin-top: 10px; margin-bottom: 26px;
        a{ display: block; line-height: 30px; max-height: 60px; overflow: hidden; }
    }
 
    .more{  margin: 0 auto; text-align: left; color: #333; display: block;  background: url(../images/m5more.png) no-repeat center center;  width: 140px; height: 45px; line-height: 45px;  text-indent: 11px; text-transform: uppercase;  }
 

    @media screen and (max-width:1219px) {

    }
    
    @media screen and (max-width:999px) {
        .title { font-size:30px;font-size:3rem; }
    }
    
    @media screen and (max-width:768px) {
        .title { font-size:22px;font-size:2.2rem; }
    }
    @media screen and (max-width:320px) {
    
    }  
}
  
// main6
.main6{ background: url(../images/main6.png) no-repeat center top; padding-bottom: 61px;

    .img-scroll { position:relative; width: 100%; margin-top: 59px;  
        .prev{position:absolute;display:block;width:18px;height:32px;z-index: 9;}
        .next{position:absolute;display:block;width:18px;height:32px;z-index: 9;}
        .prev { left:0;cursor:pointer; background:url(../images/arrowleft.png) no-repeat center;}
        .next { right:0;cursor:pointer; background:url(../images/arrowright.png) no-repeat center;} 
        .prev:hover { background:url(../images/arrowlefton.png) no-repeat center;} 
        .next:hover { background:url(../images/arrowrighton.png) no-repeat center;}  
        .hot{ position: relative;   max-width: 77px; margin: 0 auto; margin-top: 50px;  width: 100%; height:32px;}
    }
 
    .img-list { position:relative; width:100%; overflow:hidden;margin: 0 auto;
        ul { width:1000%;}
         li { float:left;  width:445px; margin-right:30px; 
            .m3tu{  display: block; margin: 10px;  }
            img{ display: block; max-width:445px; max-height:363px;width: 100%; margin: 0 auto;}
            .m4zi{ display: block; margin: 0 auto; max-width:445px; width: 100%; 
                 font-size:20px;font-size:2rem; margin-top: 15px;
                color: #333;line-height: 38px; text-align: center;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;}
            .m3lien{ max-width:445px;text-align: center; position: relative; margin: 0 auto; overflow: hidden;}
            .m3lien:hover .m3tu{ box-shadow: 0px 0px 10px rgba(0,0,0,0.5);  }
        } 
    }

  
    @media screen and (max-width:1419px) {
      
       .img-list li {  width: 305px; }
    }
    
    @media screen and (max-width:999px) {
       .img-list li .m3lien .m4fu { top: 0px; opacity: 0; }
       .img-list { width: 100%; }
       .img-scroll .hot{ position: relative; top: 0px;  max-width: 100px; width: 100%; height:50px; margin-bottom: 20px; }
       .img-list li{ width: 3%; margin-right: .5%; }
    
    }
    
    @media screen and (max-width:768px) {
 
       .img-list li { width: 4.5%; margin-right: 1%; }
    }
    @media screen and (max-width:400px) {  
       .img-list li { width: 10%; margin-right: .487%; }
    } 
}


/*==========main7==================*/
.main7{ 
 
    .m7_ul{ margin-top: 66px; padding-bottom: 55px;
        li{ width: 658px;  }
        .wrap{max-width: 658px; margin: 0 auto; position: relative;  }
        .rili { position: absolute; bottom: 0px; width: 72px; left: 50%; margin-left: -36px;background: #fff; padding-top: 13px;
            span { display: block; text-align: center; font-size: 12px; font-size: 1.2rem; line-height: 30px; 
                &:first-child{ font-size:22px;font-size:2.2rem; }
            }
            b{ display: block; height: 1px; width: 20px; background: #333; margin: 0 auto; margin-top: 4px; margin-bottom: 0px; }
        }
    }

    .m7_title{text-align: center; font-weight: bold; margin-top: 31px;  margin-bottom: 2px;
        a{ font-size:18px;font-size:1.8rem; line-height: 38px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis; display: block;  }
    }
    p{ text-align: center;
        a{ color: #666666; font-size:14px;font-size:1.4rem; line-height: 24px;  }
    }
    .more{ display: block; margin: 0 auto; width: 130px; width: 135px\0; font-weight: bold; text-transform: uppercase; margin-top: 38px;
        span{ display: inline-block; padding-right: 30px; background: url(../images/mreot.png) no-repeat right center; }
    }

    @media screen and (max-width:1419px) {
        .m7_ul{ 
            li{ width: 48%; }
        }
    }
    @media screen and (max-width:648px) {
        .m7_ul{  margin-top: 30px;
            li{ width: 100%; float: none; margin: 0 auto; margin-bottom: 30px; }
        }
        .m7_title { margin-top: 10px; }
        .more { margin-top: 10px; }
    }
}


/*==========about==================*/
.about{ 
    
    .abnav2 { text-align: center; background: url(../images/ab2xvxian.png)  repeat-x left 48px;  padding-bottom: 10px; margin-bottom: 40px;
        a { display: inline-block; width: 185px; margin-left: 0px; margin-right: 0px; font-size:16px;font-size:1.6rem; line-height: 30px;
            color: #333333; text-align: center; padding-top: 102px;
            &:hover{
                color: $color;  
            }
        }
    } 
  
    .ab21 { background: url(../images/ab2nav1.png) no-repeat top center; }
    .ab22 { background: url(../images/ab2nav2.png) no-repeat top center; }
    .ab23 { background: url(../images/ab2nav3.png) no-repeat top center; }
    .ab24 { background: url(../images/ab2nav4.png) no-repeat top center; }
    .ab21:hover,.dangqianab2.ab21 { background: url(../images/ab2nav1h.png) no-repeat top center !important; color: $color; }
    .ab22:hover,.dangqianab2.ab22 { background: url(../images/ab2nav2h.png) no-repeat top center !important; color: $color;}
    .ab23:hover,.dangqianab2.ab23 { background: url(../images/ab2nav3h.png) no-repeat top center !important; color: $color;}
    .ab24:hover,.dangqianab2.ab24 { background: url(../images/ab2nav4h.png) no-repeat top center !important; color: $color;}


    .title{ font-size:36px;font-size:3.6rem; font-weight: bold; color: $color;  }
    b{ margin-top: 13px;  margin-bottom: 22px; display: block; width: 37px; height: 3px; background: $color;  } 
 
    @media screen and (max-width:999px) {
        .abnav2  a{ width: 22%; margin: 0px 1%;  }
    }

    @media screen and (max-width:640px) {

        .title{ font-size:30px;font-size:3rem; }
        b{ margin-top: 5px; margin-bottom: 5px; }
        .abnav2 { background: none; }
        .abnav2  a{ width: 47%; margin: 2% 1%;  }
    }  
}


/*==========about==================*/
.about{  margin-bottom: 98px;

    .about_left { width: 579px;
        .dan_wrap { position: relative; overflow: hidden; max-width: 579px; max-height: 371px; } 
        .dan_wrap img { display: block; max-width: 579px; max-height: 371px; width: 100%; margin: 0 auto; }
    }
 
    .about_right { width: 596px; 
        .title { margin-bottom: 14px; padding-left: 35px; background: url(../images/wenziab.png) no-repeat left 5px; } 
        .title span {   display: block; font-size: 26px; font-size: 2.6rem; line-height: 30px; color: $color; }  
        p { font-size: 14px; font-size: 1.4rem; color: #666666; line-height: 30px; }
        .ti32{ margin-bottom: 22px; font-size:42px;font-size:4.2rem; font-weight: bold; text-transform: uppercase; }
        
    }

    .ab12_wrap{margin-top: 16px;
        p { color: #666; line-height: 30px; }
    }
    
  
    .about_ul { margin-top: 28px;
        li { float: left; margin-left: 10px; }
        li .dan_wrap { position: relative; overflow: hidden; max-width: 393px; margin: 0 auto; }
        li .dan_wrap a { display: block; }
        li .dan_wrap img { display: block; max-width: 393px; max-height: 252px; width: 100%; margin: 0 auto; }
        li .dan_wrap .dan_img2 { position: absolute; top: 100%; left: 0; width: 100%; height: 100%; }
        li .dan_wrap .dan_img3 { position: absolute; bottom: 0; left: 0; width: 100%; z-index: 9999; height: 53px; line-height: 53px; background: url(../images/aboutbj.png) repeat center center; }
        li .dan_wrap .dan_img3 span { text-align: left; box-sizing: border-box; padding: 0px 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; color: #fff; display: block; }
    }
 
    .about_ul li:hover .dan_wrap .dan_img2 { top: 0px; } 
    .about_left:hover .dan_wrap .dan_img2 { top: 0px; }
    
    @media screen and (max-width: 1219px) { .about_left { width: 49%; }
      .about_right { width: 49%; }
      .about_ul li { width: 31%; margin-left: 1% !important; margin-right: 1%; } }
    
    @media screen and (max-width: 999px) {
        .about_right .ti32 { font-size:30px;font-size:3rem; }
        .about_left { width: 100%; float: none; }
      .about_right { width: 100%; float: none; margin-top: 20px; }
      .about_ul li .dan_wrap .dan_img2 { top: 0px; opacity: 0; } }
    
    @media screen and (max-width: 768px) { 
        .about_right .ti32{ font-size:24px;font-size:2.4rem; }  
        .about_right .title { margin-bottom: 10px; }
      .about_ul li { width: 100%; margin: 0 auto; margin-bottom: 20px; }
      .about .topcat .item { margin-left: 20px !important; margin-right: 20px; } }
 
}

// faq
.faq{padding-bottom: 45px;
    li{ padding-left: 52px; background: url(../images/faq.png) no-repeat left top; border-bottom: 1px solid #cccccc; padding-bottom: 21px; margin-bottom: 26px; }
    .totle3{font-size:16px;font-size:1.6rem; line-height: 30px; }
    p{ color: #666666; line-height: 30px; margin-top: 13px; }
}

/***********************************************************************  video  ****************************************************************/
 .ab3_wrap{ padding-bottom: 60px; }
.vilie { width: 382px; float: left; margin-left: 27px; margin-bottom: 29px; }

.vitu { position: relative; padding-bottom: 53.6%; height: 0; overflow: hidden; }

.vitu iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none; }

.vitu iframe body { margin: 0px !important; }

.vizi { background: #ededed; padding: 7px 10px; padding-top: 8px; }

.vizi p { font-size:16px;font-size:1.6rem; padding-left: 46px; background: url(../images/bofang.png) no-repeat left center; line-height: 46px; height: 46px; overflow: hidden; }

 
@media screen and (min-width: 1000px) and (max-width: 1219px) { .vilie { width: 31.333%; margin-left: 1% !important; margin-right: 1%; margin-bottom: 0px; } }

@media screen and (max-width: 999px) { .vimain { padding-bottom: 28px; }
  .vilie { width: 31.333%; margin-left: 1% !important; margin-right: 1%;   }
  .vizi p { font-size: 14px; font-size: 1.4rem; } }

@media screen and (max-width: 749px) { 
    .ab3_wrap{ padding-bottom: 20px; }
    .vimain .title4 { margin-bottom: 0px; }
  .vimain .title3 { font-size: 30px; font-size: 3rem; margin-top: 0px; }
  .vilie { width: 48%; }
  .vizi p { background: none; padding-left: 0px; height: 30px; line-height: 30px; } }


 
.ablie2{ width:245px; float:left; margin-left:73px; margin-bottom: 41px;}
.abtu2{position:relative; overflow:hidden;display: block;}
.abtu2 img{width:100%;max-width:245px;max-height:349px;display: block;}
.fangda{  display:block; position:absolute; width:100%; height:100%; top:100%; left:0px;transition: 0.5s;} 
.ablie2 p{text-align:center; margin-top:16px;overflow: hidden;font-family: Verdana, Geneva, Tahoma, sans-serif;}
.ablie2:hover .fangda{ top:0px;}


@media screen and (max-width:1219px) {
    .ablie2{ width: 31%; margin-left: 1%!important; margin-right: 1%; }
}


@media screen and (max-width:999px) {
    .ablie2 .fangda { top: 0px; opacity: 0; }

}

@media screen and (max-width:648px) {
    .ablie2{ width: 48%; margin-left: 1%!important; margin-right: 1%; margin-bottom: 20px; }
}


.ab4_wrapq{
    .img-scroll {position: relative;margin: 0 auto;width: 100%;margin-top: 19px;}
    .img-scroll .prev, .img-scroll .next {position: absolute;display: block;width: 31px;height: 31px;top:0;color: #fff;text-align: center;line-height: 100px}
    .img-scroll .prev {right: 0;margin-right:40px;cursor: pointer;background: url("../images/zss.png") no-repeat;}
    .img-scroll .next {right: 0;margin-right:3px;cursor: pointer;background: url("../images/zsx.png") no-repeat;}
    .img-scroll .prev:hover {background: url("../images/zss2.png") no-repeat;}
    .img-scroll .next:hover {background: url("../images/zsx2.png") no-repeat;}
    .img-list {position:relative;width:100%;overflow: hidden;margin: 0 auto;padding-top: 58px;}
    .img-list ul {width: 999999px;}
    .img-list li {float: left;display: block;width: 358px;position: relative;position: relative;margin-right: 63px;}
    
    .hzs img{width: 100%;max-width: 358px;max-height: 248px;}
    .hzsp{text-align: center;display: block;line-height: 36px;margin-top: 5px;}

    

    @media screen and (min-width:1000px) and (max-width:1219px) { 
        .img-list li{width: 285px;}
    }
    @media screen and (max-width:999px) { 
    .img-list ul{width: 10000%;}
    .img-list li{width: 0.31333%;margin: 0 0.01%;}
    .img-scroll{margin-top: 20px;}
    .img-list{padding-top: 55px;} 
    .fangda{top: 0;background: none;} 

    }
    @media screen and (max-width:639px) {  
    .img-list li{width: 0.48%;margin: 0 0.01%;} 
    } 
}




/*==========product==================*/
.product{  
 
    .pr_r{ width: 828px; padding-bottom: 100px; }
    .list {width: 321px; }
 
    // pr_ul
    .pr_ul{ padding-bottom: 60px;
        li{ float: left; margin-left: 18px; margin-bottom: 21px; 
            &:hover{

                .img{ box-shadow: 0px 0px 5px rgba(0,0,0,0.5);  }
                .chanmor a {  background: $color; color: #fff; }
            }
        }
        .wrap{max-width: 264px; margin: 0 auto; overflow: hidden;  }
        .img{ display: block; border: 1px solid #cccccc; box-sizing: border-box; margin: 10px;
            img{ display: block; max-width: 262px; max-height: 240px; width: 100%; margin: 0 auto;}
        }
    
        .przz{ margin-top: 16px; margin-bottom: 12px;
            a{  font-size:16px;font-size:1.6rem; display: block; text-align: center; line-height: 30px;  display: block;  white-space:nowrap; overflow:hidden; text-overflow:ellipsis; }
        }
     
        .chanmor { text-align: center; } 
        .chanmor a {margin-left: 10px; display: inline-block; font-size: 14px; font-size: 1.4rem;  text-align: center; width: 139px; height: 31px; line-height: 29px; border: 1px solid $color; color:$color; font-size:14px;font-size:1.4rem; color: $color;  } 
        .chanlien input { display: inline-block; left: 9px; top: 11px; cursor: pointer; margin-right: 5px; }
     
    }

        
    /*list*/
    
    .list .title1{width: 100%; } 
    .list .title1 a{display: block;width: 100%;height: 50px;line-height: 50px;   text-transform: uppercase;
        background-color: $color; font-size:24px;font-size:2.4rem;color:$color;  font-weight: bold; color: #fff; text-align: center;}
    .list .title2{width: 100%; margin-top: 51px; margin-bottom:2px;}
    .list .title2 a{display: block; padding-left: 16px; height: 46px;line-height: 46px; text-transform: uppercase;  
        background-color: $color; font-size:22px;font-size:2.2rem;color: #fff; font-weight: bold;   }
    
       
    .list .subNav1{cursor: pointer; background: #e4e5e6; margin-bottom: 0px;}
    .list .subNav1.div1{margin-top: 0px;padding-top: 0px;}
    .list .subNav1 a{ line-height:41px; padding-left:13px;  color:#333333; transition:0s;}
    .list .subNav1:hover { background:$color; color:#fff;}
    .list .subNav1:hover a{ color:#fff!important;}
    .list .subNav1.subNav1on{ background:$color; color:#fff;}
    .list .subNav1.subNav1on a{ color:#fff!important;}

    .list .subNavBox{width: 100%;}
    .list .subNav{cursor: pointer; background: #e4e5e6 url(../images/parrow.png) no-repeat 98% 12px;margin-top: 10px;margin-bottom: 0px;}
    .list .subNav.div1{margin-top: 0px;padding-top: 4px;}
    .list .subNav a{ line-height:41px; padding-left:13px;  color:#333333; transition:0s;}
    .list .subNav:hover { background:$color url(../images/parrowon.png) no-repeat 98% 12px; color:#fff;}
    .list .subNav:hover a{ color:#fff;}
    .list .currentDd{background:$color url(../images/parrowon.png) no-repeat 98% 12px;  }
    .list .currentDd a{ color:#fff;}
    .list .navContent{ padding-top: 8px; margin-top: 2px;  display: none; background:#fafafa;position: relative; padding-bottom: 11px;top:0px;}
    .list .navContent>li>a{ background: url( ../images/peirb.png ) no-repeat 17px center;  display:block; text-transform: uppercase;
        font-size:14px; font-size:1.4rem; line-height: 30px; color:#333333; padding-left:43px;}
    .list .navContent>li a:hover{  color: $color;}


    .div_p{ padding-left: 63px;
        p{
            a{ display: block; font-size:14px;font-size:1.4rem; line-height: 30px;  }
        }
    }


    .titlems {  position: relative; }  
    .titlems .dan_p {  display: none; position: absolute; right: 5px; top: 15px; display: none;  } 
    .titlems .dan_p span { margin-bottom: 2px; display: block; width: 25px; height: 4px; background-color: #FFF ; }


    .pro_new{
        background: #eeeeee;  padding:16px;
        .title{ margin-top: 7px; margin-bottom: 13px;  display: block; line-height: 24px;  font-weight: bold;  }

        a.title24{ font-size:15px;font-size:1.5rem;  margin-bottom: 24px; display: block; line-height: 30px; padding-left: 34px;  white-space:nowrap; overflow:hidden; text-overflow:ellipsis; background: url(../images/fapd.png) no-repeat left top;  font-weight: bold;  }
        p{
            a{ display: block; color: #666666; font-size:14px;font-size:1.4rem; line-height: 24px;  max-height: 48px; overflow: hidden; }
        }
        .more{ display: block; margin-top: 9px; color: #333; font-size:14px;font-size:1.4rem; line-height: 30px;  }
        .more2{ color: $color; }
        
    }
    .pro_new2{  border-top: 1px solid #cccccc;  }

    .pro_new .more:hover{ color: $color; }

 
  
    @media screen and (max-width:1219px) {
        .pr_r{ width: 630px; }
        .list {width: 321px; }

        .pr_ul li{ float: left; width: 31%; margin-left: 1%!important; margin-right: 1%; }
        .pr_ul .peo_wrap{ padding-left: 10px; padding-right: 10px; }
    }
    
    @media screen and (max-width:999px) {
        .pr_ul{ padding-bottom: 20px; }
        .pr_r{ width: 100%; float: none; margin-bottom: 0px; padding-bottom: 30px; }
        .list {width: 100%; float: none; margin-bottom: 30px; }

        .list .subNavBox{ display: none;  }
        .titlems .dan_p { display: block; }
    }
    
    @media screen and (max-width:768px) {
        .list .title1 a { font-size:18px;font-size:1.8rem; }

        .pr_ul li{ width: 48%; }
        .pr_ul .wenzi .more{ max-width: 120px; width: 80%; font-size:14px;font-size:1.4rem; }
    }
    @media screen and (max-width:370px) { 
        .pr_ul .chanmor a { width: 100px; }
    
    } 
}

.product .list { margin-bottom: 20px; }




/*pr_r */ 
.pr_r {

    /*mouse hands*/
    #tsShopContainer li,#tsShopContainer img{vertical-align:top;}
    #tsShopContainer{position:relative; float:left; 
    width:419px;}
    #tsShopContainer #tsImgS{text-align:center;width:100%;position:relative;}
    #tsShopContainer #tsImgS a{display:block;text-align:center;margin:0px auto; width:419px!important;  }
    #tsShopContainer #tsImgS img{  border: 1px solid #cccccc;   }
    #tsShopContainer #tsPicContainer{width:100%;position:relative;
    height:99px;
    margin-top: 11px;
    }
    #tsShopContainer #tsPicContainer #tsImgSArrL{width:21px;position:absolute;top:0px;left:0px;cursor:pointer;
    height:99px;background:url(../images/prleft.png) no-repeat left center;}
    #tsShopContainer #tsPicContainer #tsImgSArrR{width:21px;position:absolute;top:0px;right:0px;cursor:pointer;
    height:99px;background:url(../images/prright.png) no-repeat right center;} 
    #tsShopContainer #tsPicContainer #tsImgSArrL:hover{background:url(../images/prlefth.png) no-repeat left center;}
    #tsShopContainer #tsPicContainer #tsImgSArrR:hover{background:url(../images/prrighth.png) no-repeat right center;} 
    #tsShopContainer #tsPicContainer #tsImgSCon{position:absolute;top:1px;left:40px;width:1px;overflow:hidden;
    height:99px;}
    #tsShopContainer #tsPicContainer #tsImgSCon ul{width:100%;overflow:hidden;}
    #tsShopContainer #tsPicContainer #tsImgSCon li{width:117px;float:left;cursor:pointer;margin-left: 0px;margin-bottom: 0; }
    #tsShopContainer #tsPicContainer #tsImgSCon li a{ display:block; 
    width:99px;}
    #tsShopContainer #tsPicContainer #tsImgSCon li img{display:block; border: 1px solid #cccccc; box-sizing: border-box; 
    width:104px;
    height:94px;} 
    /*
    teer.js
    tsScrollResize
    offsetWidth*3
    */
    .MagicZoomBigImageCont{border:1px solid #f0f0f0;background:#FFF;}
    .MagicZoomPup{border:0px solid #aaa;background:#ffffff;}
    .MagicZoomMain{text-align:center !important;width: 92px;} 
    .MagicZoomBigImageCont img{ max-width: 838px;display: block; border:0px solid #aaa;}  

    
    .content{width: 357px } 
    h1{ font-size:24px;font-size:2.4rem; line-height: 43px; font-weight: bold; margin: 0 auto; color: $color; text-transform: uppercase; } 
    .btnt{  max-width: 330px; width: 100%;  margin-top: 73px;   margin-bottom: 24px;
        a , span{display: block; font-size:14px;font-size:1.4rem;color:$color; border: 1px solid $color;  
            width: 138px;height: 31px;line-height: 31px;text-align: center; background: transparent;   
            &:hover{
                background: $color; color: #fff!important; 
            }
        } 
    }
    
    .share1 { position: relative;  top: 0px; display: inline-block; width: 235px; margin-top: 4px;  }
    .share1 .span4 { font-size: 14px; font-size: 1.4rem; color: #666666; height: 30px; line-height: 30px; }
    .at-resp-share-element .at-icon { width: 26px !important; height: 26px !important; }
    .at-style-responsive .at-share-btn { padding: 0 !important; border-radius: 2px !important; }
    .at-resp-share-element .at-share-btn .at-icon-wrapper { width: 26px !important; height: 26px !important; }
    .at-resp-share-element .at-share-btn { margin-bottom: 0 !important; margin-right: 3px !important; }
    
    
    .img980{display: none; 
        
        .img-scroll { position: relative; width: 100%; } 
        .img-scroll .prev { position: absolute; display: block; width: 16px; height: 16px; top: 50%; margin-top: -8px; z-index: 99; } 
        .img-scroll .next { position: absolute; display: block; width: 16px; height: 16px; top: 50%; margin-top: -8px; z-index: 99; } 
        .img-scroll .prev { left: 0; cursor: pointer; background: url(../images/prleft.png) no-repeat center; } 
        .img-scroll .next { right: 0; cursor: pointer; background: url(../images/prright.png) no-repeat center; }
        .img-scroll .prev:hover { left: 0; cursor: pointer; background: url(../images/prlefth.png) no-repeat center; } 
        .img-scroll .next:hover { right: 0; cursor: pointer; background: url(../images/prrighth.png) no-repeat center; }
 
        .img-list { position: relative; width: 100%; overflow: hidden; margin: 0 auto; }
        
        .img-list ul { width: 1000%; }
        
        .img-list li { float: left; width: 3%; margin-right: .5%; }
        
        .img-list li .m3lien { max-width: 213px; text-align: center; position: relative; overflow: hidden; box-sizing: border-box; border: 1px solid #ccc; margin: 0 auto; }
        
        .img-list li .m3lien img { display: block; max-width: 213px; max-height: 213px; width: 100%; margin: 0 auto; }
         
    }


    @media screen and (max-width:1220px) {
        .MagicZoomBigImageCont  { display: none!important; }  
        .MagicZoomMain { display: none!important; }  
        .MagicZoomPup{ display: none!important; }

        .content{ width: 100%; float: none; margin-top: 20px; }
        #tsShopContainer { margin: 0 auto; max-width: 437px;  width: 100%; float: none; } 
    
    }

    @media screen and (max-width:999px) {

        #tsShopContainer { display: none; }
        .img980{display: block; 
            .img-scroll { margin-top: 0px;  }
        } 
        .btnt{  margin-top: 20px; }
    }  

    @media screen and (max-width:768px) { 
        .img980 .img-list li { width: 4.5%; margin-right: 1%; }
     }

}
.pro_x{ font-size:14px;font-size:1.4rem; color:#666666; line-height: 30px; margin-top: 20px;  
    p,span,li{  color:#666666; }
    a{ color:$color; line-height: 24px; }
    img{ display: block; max-width: 100%; margin: 0 auto;}
    iframe{ display: block; max-width: 100%; margin: 0 auto;}
    table{ width:90%; border-collapse:collapse; line-height:22px; border:1px solid #ccc; margin-left:auto; margin-right:auto}
    table tr td{ padding:3px; border:1px solid #ccc; background:#fff}
} 

    
.pro_x2{ font-size:14px;font-size:1.4rem; color:#666666; line-height: 30px; margin-top: 20px;  
    p,span,li{  color:#666666; }
    a{ color:$color; line-height: 24px; }
    img{ display: block; max-width: 100%; margin: 0 auto;}
    iframe{ display: block; max-width: 100%; margin: 0 auto;}
    table{ width:90%; border-collapse:collapse; line-height:22px; border:1px solid #ccc; margin-left:auto; margin-right:auto}
    table tr td{ padding:3px; border:1px solid #ccc; background:#fff}
} 



// #tab 
.product{

    #tab {overflow:hidden; width:100%;   margin-top: 41px; }
    #tab li{ height: 52px; line-height: 52px; text-align: center; float:left; cursor:pointer; width: 50%; background: #f5f5f5; font-weight: bold;  font-size: 24px; font-size: 2.4rem; }

    #tab li:hover{ background: #666666; color:#fff;}
    #tab li.current { background: #666666; color:#fff;}
    #maln{ margin-left: 0!important;  }

    #content .chanxn { display:none;  padding-top:15px; width:100%;}

    .por_wrotr{
        font-size:14px;font-size:1.4rem; color:#666666; line-height: 30px; margin-bottom: 20px;  
        p,span,li{  color:#666666; }
        a{ color:$color; line-height: 24px; }
        img{ display: block; max-width: 100%; margin: 0 auto;}
        iframe{ display: block; max-width: 100%; margin: 0 auto;}
        table{ width:90%; border-collapse:collapse; line-height:22px; border:1px solid #ccc; margin-left:auto; margin-right:auto}
        table tr td{ padding:3px; border:1px solid #ccc; background:#fff}
    }
 
    .down{    padding-top: 19px;
        p { padding-bottom: 20px; margin-bottom: 30px; position: relative; background: url(../images/down.png) no-repeat left 5px; padding-left: 34px; padding-right: 140px;  border-bottom: 1px dashed #ccc;  font-size:16px;font-size:1.6rem; line-height: 30px;  }
        a{ display: block; position: absolute; right: 0px; top: 0px; background: url(../images/wnono.png) no-repeat left top; width: 138px; height: 36px;  line-height:  36px;
             color: #fff; text-indent: 50px; font-size:16px;font-size:1.6rem; }
    }

        
    .m-mpro { margin-top: 30px; }

    .m-mpro .box-hd { position: relative; padding-bottom: 7px; line-height: 30px; font-size: 26px; font-size: 2.6rem; font-weight: bold; color: $color; border-bottom: 1px solid #ccc; }

    .m-mpro .box-title {   font-weight: bold; font-size:20px;font-size:2rem; }

    .m-mpro .box-btn { position: absolute; top: 6px; width: 13px; height: 16px; width: 16px\0; height: 21px\0; background-repeat: no-repeat; background-position: center; }

    .m-mpro .box-btn.prev { right: 33px; background-image: url("../images/prleft.png"); background-size: contain; }

    .m-mpro .box-btn.prev:hover { background-image: url("../images/prlefth.png");  background-size: contain;}

    .m-mpro .box-btn.next { right: 0; background-image: url("../images/prright.png");  background-size: contain;}

    .m-mpro .box-btn.next:hover { background-image: url("../images/prrighth.png");  background-size: contain;}

 
    .m-mpro .box-bd { margin-top: 30px;   overflow: hidden;  background-size: contain;}

    .m-mpro .box-list { width: 1000%; overflow: hidden; }
    .m-mpro .box-list li{ margin-left: 0px; margin-right: 18px; }


    
 
    @media screen and (max-width:1219px) { 
        .m-mpro .box-list li{ width: 3%; margin-right: .5%; margin-left: 0px!important; }
     }
     
     @media screen and (max-width:999px) {  
        .m-mpro .box-list{ padding-bottom: 0px; }
        .m-mpro .box-list li{ width: 3%; margin-right: .5%; }
     
     }
     
     @media screen and (max-width:768px) {
        .img-scroll { margin-top: 20px; } 
        .m-mpro .box-list li { width: 4.5%; margin-right: 1%; }
     }


    @media screen and (max-width: 640px) { 

        .down{ padding-top: 19px;
            p { padding-right: 0px;   }
            a{ display: block; position: relative; right: 0px; top: 0px;  margin-top: 10px;}
        }
 

        #tab li{ font-size:20px;font-size:2rem;   }
        .m-prob1 .prob-info-title { font-size:20px;font-size:2rem; } 
        .m-mpro .box-title { font-size:20px;font-size:2rem; }
        .m-mpro .list-item { margin-bottom: 0px; margin-left: .1%; margin-right: .1% !important; width: 4.8%; }
        .m-mpro .item-title { font-size: 14px; font-size: 1.4rem; }
        .m-mpro .item-desc a { font-size: 14px; font-size: 1.4rem; } 
    } 


    @media screen and (max-width: 500px) { 
        #tab li{ width: 100%; float: none;  font-size:14px;font-size:1.4rem; white-space:nowrap; overflow:hidden; text-overflow:ellipsis; padding: 0px 10px;box-sizing: border-box; }

    }

}


/*==========application==================*/
.application{   padding-bottom: 100px;
    .news_wrp{ margin-bottom: 40px; }

    .nelie{ border:1px solid #f6f6f6; }
    .nelie1{ padding-top:10px; padding-left:30px;padding-right:10px; padding-bottom:10px; background: #f6f6f6; margin-top: 35px; }
    .nelie2{padding-top:10px; padding-right:30px; padding-left:10px; background: #f6f6f6;padding-bottom:10px; margin-top: 35px; }
    .nelie1 .nelietu{ float:right}
    .nelie1 .nelien{ float:left}
    .nelie2 .nelietu{ float:left}
    .nelie2 .nelien{ float:right}
    .nelietu{ display:block; width:413px;}
    .nelietu img{ display: block; max-width:413px; max-height:336px; width: 100%; margin: 0 auto; }
    .nelien{ width:625px; padding-top: 54px; }
    .neliebiao{ display:block; font-size:30px;font-size:3rem; color: $color; font-weight: bold; text-transform:uppercase; white-space:nowrap; overflow:hidden; text-overflow:ellipsis; display: block; line-height: 30px; }
    .neliebiao:hover{color:$color}
    .nelien p{   margin-top:23px;     margin-bottom: 28px; }
    .nelien p a{ display:block; color:#4d4d4d; line-height:24px;  max-height: 72px; overflow: hidden; }
    .nelien p a:hover{ color:$color}  
    .neliemor{  margin-top:69px;}
    .neliemor a{ border-radius: 50px; display:block; width:160px; height:38px; border: 1px solid $color; color:#333333; line-height:38px; text-align:center;  font-weight: bold;  } 
    .neliemor a:hover{ background:$color; color:#fff; }
     
    .nelie2 span{ float:left; margin-left:10px;}


    @media screen and (max-width:1219px) { 
        .nelie1 .nelien{ width:513px;} 
        .nelie1 .nelietu{ width:300px;} 

        .nelien{ width:513px;} 
        .nelietu{ width:300px;} 

    }
    
    @media screen and (max-width:999px) {
        padding-bottom: 80px;
        .nelie1 .nelien{ width:100%; float: none; margin: 0 auto; } 
        .nelie1 .nelietu{  width:100%; float: none; margin: 0 auto; margin-bottom: 20px; } 

        .nelien{ width:100%; float: none; margin: 0 auto;  padding-top: 10px; } 
        .nelietu{  width:100%; float: none; margin: 0 auto; margin-bottom: 20px; } 
        .nelien p { margin: 10px 0px; }
        .nelien p a{ max-height: 108px; overflow: hidden; }
 
        .neliemor{  width: 100%; margin-top: 10px; }
        .nelie1 { padding: 20px; }
        .nelie2 { padding: 20px; }
    }
    
    @media screen and (max-width:768px) {
        padding-bottom: 30px;
        .nelie1 { padding: 10px; }
        .nelie2 { padding: 10px; }
        .neliebiao{ font-size:20px;font-size:2rem; }
        .nelien p a{ font-size:14px;font-size:1.4rem; line-height: 30px; max-height: 90px; overflow: hidden; }
 
        .neliemor a{ max-width: 278px; width: 100%; }
    }

} 



// new_topwrap
.new_topwrap{border-left: 1px solid #dcdcdc; margin-bottom: 60px;
    a{ border-right: 1px solid #dcdcdc;  display: block; float: left; width: 298px; height: 60px; line-height: 60px;  text-align: center; background: #f0f0f0; font-size:16px;font-size:1.6rem; }
    a:hover,a.on{ background: #333; color: #fff;   }

    @media screen and (max-width:1219px) {
        a{ width: 25%; box-sizing: border-box;  }
    }

    @media screen and (max-width:999px) {
        a{ width: 33.33%; box-sizing: border-box;  }
    }

    @media screen and (max-width:640px) {
        border-top: 1px solid #dcdcdc;
        a{ width: 100%; float: none; box-sizing: border-box;border-top: 1px solid #dcdcdc;   }
    }
}



 
/*==========application==================*/
.application {position: relative; 
    .title1{text-align: center;}
    .title1 h1{ font-size:24px;font-size:2.4rem; font-weight: bold; }
 
    .news2x{font-size:14px;font-size:1.4rem;color:#333; line-height: 30px; margin-top: 30px;
        a{ color:$color; line-height: 24px; }
        img{ display: block; max-width: 100%; margin: 0 auto;}
        iframe{ display: block; max-width: 100%; margin: 0 auto;}
        table{ width:90%; border-collapse:collapse; line-height:22px; border:1px solid #ccc; margin-left:auto; margin-right:auto}
        table tr td{ padding:3px; border:1px solid #ccc; background:#fff}
    }   
    
    .updown{ margin-top: 20px; padding-top: 31px; border-top: 1px solid #cccccc;  
        .up{margin-top: 0px;margin-bottom: 0px;}
        p{ line-height: 32px;font-size: 14px;font-size:1.4rem;color: #333333;}
        
        p .img{ display: inline-block; }
        p .img img{ display: inline-block; position: relative;  top:7px; }
        
        p .on{color:$color; }
        p span{ font-size: 14px;font-size:1.4rem;color: #333333;}
        p a{ font-size: 14px;font-size:1.4rem;color: #333333;}
        p a:hover{color: $color;}
    }


    @media screen and (max-width:768px) { 
        .updown{   margin-bottom: 40px;}
        .title1 h1{ font-size:16px;font-size:1.6rem; line-height: 30px; }
    } 

}







// .m-ctusinfo start
.m-ctusinfo{
    width: get_w(480);

    .box-title{
        line-height: 26px;
        @include font-size(18);
        color: #333333;
    }

    .list-item{
        padding: {
            left: 32px; 
        };
        line-height: 44px;
        @include font-size(14);
        color: #333333;
        background-repeat: no-repeat;  
        a{
            color: #333;

            &:hover{
                color: $color;
            }
        }
    }

    .list-item-1{ background: url('../images/cicon1.png') no-repeat left 15px; } 
    .list-item-2{ background: url('../images/cicon2.png') no-repeat left 10px; }  
    .list-item-3{ background: url('../images/cicon3.png') no-repeat left 10px; }   
    .list-item-4{ background: url('../images/cicon4.png') no-repeat left 16px; } 
    .list-item-5{ background: url('../images/cicon5.png') no-repeat left 10px; } 
    .list-item-6{ background: url('../images/cicon6.png') no-repeat left 10px; }
    .list-item-7{ background: url('../images/cicon7.png') no-repeat left 10px; }  

}

@media screen and (max-width: 959px){
    .m-ctusinfo{
        float: none;
        width: 100%;
    }
}
// .m-ctusinfo end
// .m-ctusform start
.m-ctusform{
    float: right;
    width: get_w(670);
    overflow: hidden;

    
    .form-item{
        float: left;
        position: relative;
        margin: {
            bottom: 22px;
        };
        padding-left: 22px;
        line-height: 0;
        font-size: 0;
        box-sizing: border-box;
        overflow: hidden;

        &.important{
            background: url('../images/star2.png') no-repeat 8px 28px;
        }
    }

    .form-item-1{
        width: 50%;
    }

    .form-item-2{
        width: 100%;

        &.important{
            background: url('../images/star2.png') no-repeat 8px 18px;
        }
    }

    .form-item-3{
        margin: {
            right: get_w(22, 670);
        };
        width: get_w(200, 670);
    }

    .form-item-4{
        width: get_w(176, 670);
        height: 54px;
        padding-left: 0;
        line-height: 52px;
        text-align: center;
        background-color: transparent;
        border-radius: 0;
        background-color: #fff;
        overflow: hidden;
        box-sizing: border-box;
        border: 1px solid #d2d2d2;

        img{
            max-height: 52px;
            vertical-align: middle;
        }
    }
    
    .form-item-5{
        float: right;
        border: none;
        width: get_w(250, 670);
        padding-left: 0;
    }

    
    .item-btn1{
        width: 100%;
        height: 54px;
        padding-left: 0;
        line-height: 54px;
        color: #ffffff;
        @include font-size(14);
        text-align: center;
        text-transform: uppercase;
        background-color: $color;
    }

    .item-text{
        width: 100%;
        height: 54px;
        line-height: 52px;
        padding: {
            left: 14px;
            right: 14px;
        };
        @include font-size(14);
        color: #666666;
        box-sizing: border-box;
        border: 1px solid #d2d2d2;
    }

    .item-text2{
        height: 193px;
        line-height: 40px;
    }
}

@media screen and (max-width: 959px){
    .m-ctusform{
        float: none;
        margin-top: 30px;
        width: 100%;
    }
}

@media screen and (max-width: 767px){
    .m-ctusform{
        .form-item{
            margin-bottom: 15px;
            width: 100%;

            &.important{
                background-position: 5px 13px;
            }
        }

        .item-text{
            height: 35px;
            line-height: 35px;
            @include font-size(14);
        }

        .item-text2{
            height: 35px * 4;
        }
    }
}
// .m-ctusform end

.m-ctusmap{ margin-top: 29px;  margin-bottom: 100px;

    img{display: block; max-width: 1200px; width: 100%; margin: 0 auto; }
}


@media screen and (max-width:999px) {
    
.m-ctusmap{ margin-top: 30px;  margin-bottom: 30px; }
.m-ctusinfo .box-title{ font-size:16px;font-size:1.6rem; }
}






/* ======================================== news ======================================== */
/*new*/
.news {  padding-bottom: 100px; }
.new_dl{  padding-bottom: 53px; }
.new_wrap{ padding-bottom: 41px; }
.new_dl.new_dl2{ margin-left: 69px;}
.new_dl dt{ width: 450px;}
.new_dl dt a{ display: block;}
.new_dl dt a img{ display: block; max-width: 450px; width: 100%;}
.new_dl dd{ width: 577px; margin-left: 56px; padding-top: 0px; }
.new_dl dd .title{ font-family: Tahoma; font-size: 72px; font-size: 7.2rem; color: #d0d0d0; margin-top: -12px; }
.new_dl.new_dl2 dd .title{margin-top: -19px; }
.new_dl dd .title span{  font-size: 18px; font-size: 1.8rem; color: #999999;  }
.new_dl dd .title2{ padding-top: 25px;  padding-bottom: 9px; display: block;  font-size: 16px; font-size: 1.6rem; line-height: 30px; font-weight: bold; white-space:nowrap; overflow:hidden; text-overflow:ellipsis }
.new_dl dd .title3{ display: block; line-height: 30px; max-height: 90px; overflow: hidden; margin-bottom: 33px; color: #666666;   font-size:14px;font-size:1.4rem;}
.new_dl dd .more{ display: block; text-transform: uppercase; font-family: Tahoma; border: 1px solid $color; color: $color;   border-radius: 50px;font-size:14px;font-size:1.4rem;
     width: 141px;height: 42px; line-height: 42px; text-align: center;   }
.new_dl dd .more:hover{background: $color; color: #fff;  }
.new_dl dd a:hover{ color: $color;  } 
 

@media screen  and (max-width:1219px) {
    .new_dl dt{ width: 377px;}
    .new_dl dd{ width: 530px; padding-top: 0px; }
    .new_dl.new_dl2{ margin-left: 0px; }
}
@media screen  and (max-width:999px) {
    .new .page {  margin: 20px auto 30px;}
    .new_dl dt{ width: 100%;}
    .new_dl dd{ width: 100%; margin-left: 0px; padding-top: 0px; }
    .new_dl.new_dl2 dd .title{margin-top: 10px;}
    .new_dl dd .title{margin-top: 10px;}
    .new_dl{padding-bottom: 40px;}
    .new_dl dt a img{ display: block; margin: 0 auto; }
    .new_dl dd .more{height: 30px;line-height: 30px;}
}
@media screen  and (max-width:768px) {
  .new_dl dd .title span{font-size:16px;font-size:1.6rem;}
  .new_dl dd .title2{font-size:14px;font-size:1.4rem;}
  .new_dl dd .title3{font-size:13px;font-size:1.3rem;}
}
@media screen  and (max-width:640px) {
    .new_dl{ margin-top: 0px; }
    .new_dl dd .title2{ padding-top: 10px; }
    .new_dl dd .title{ font-size: 40px; font-size: 4rem; padding-top: 10px; }
}






/* new2 */

.new2{  padding-bottom: 30px;
    .new2_left{ width: 798px;} 
    .xqy {position: relative;} 
    .xqy .title1 h1{font-size: 16px;font-size:1.6rem;color: #333;font-weight: bold; line-height: 30px;}
    .xqy .time{margin-top: 9px;margin-bottom: 18px;}
    .xqy .time span{font-size: 14px;font-size:1.4rem;color: #999;font-weight: normal;display: inline-block;line-height: 26px; }

    .newsx {color: #666666; font-size:14px;font-size:1.4rem; line-height: 28px;margin-bottom: 26px;
        p{color: #666666;}
        span{color: #666666;}
        li{color: #666666;}
        strong{color: #666666;}
        img{display: block;max-width: 100%; margin: 0 auto;  } 
        a{color: $color;} 
        table{ width:90%; border-collapse:collapse; line-height:22px; border:1px solid #ccc; margin-left:auto; margin-right:auto}
        table tr td{ padding:3px; border:1px solid #ccc; background:#fff}
    }

    .updown{padding-top: 15px; border-top: 1px solid #d2d2d2;margin-bottom:64px;
        .up{margin-top: 18px;margin-bottom: 6px;}
        p{ display: inline-block; text-transform: uppercase;  line-height: 28px;font-size: 14px;font-size:1.4rem;color: #666666;} 
        p .img{ display: inline-block; }
        p .img img{ display: inline-block; position: relative;  top:7px;  padding-right: 4px; } 
        p .on{color:$color;  }
        p a{ font-size: 14px;font-size:1.4rem;color: #333; text-transform: none; }
        p:hover a{color: $color; }  
    }

  
    .new2_right{ 
        .back{ display: block;  font-size:18px;font-size:1.8rem; max-width: 222px; width: 100%; margin: 0 auto;
             height: 51px; line-height: 51px; text-align: center; color: #fff; background: $color;  margin-bottom: 35px; border-radius: 10px; }
        .title{ padding-top: 14px;  margin-bottom: 9px; display: block; font-size:24px;font-size:2.4rem;color:$color;
             line-height: 40px; font-weight: bold;    }
      
        .inpu{ text-align: center; margin-bottom: 24px;   }
        .inpu a{ display: inline-block; width: 130px; height: 38px; line-height: 38px; text-align: center; margin-left: 25px; margin-right: 25px;
        font-size:14px;font-size:1.4rem;color:$color; border-radius: 50px; border: 1px solid $color;  }
        .inpu a:hover{  background: $color; color: #fff; } 

        .ex_wrap{ border: 1px solid #b3d3ec; width: 353px; background: #fff;  margin: 0 auto; padding-bottom: 40px;  
            dl{ max-width: 319px; width: 100%; margin: 0 auto; margin-bottom: 17px;}
            dl { display: block;  }
            a{ display: block;  }
            dl a.we_tit{ margin-top: 14px; font-size:16px;font-size:1.6rem; font-weight: bold; color:#333; line-height: 24px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis  }
            dl .titlewen{ line-height: 30px; color: #999999; } 
           img{ display: block; max-width:319px; max-height: 209px; width: 100%;  }  
           .top_imgtd{ display: block; width: 8px; height: 20px;  margin-bottom: 30px; } 
        }

        .share1 { position: relative;   display: block; width: 240px; margin: 0 auto;  margin-bottom: 46px; }
        .share1 .span4 { font-size:16px;font-size:1.6rem; color: $color; height: 26px; line-height: 26px; }
        .at-resp-share-element .at-icon { width: 26px !important; height: 26px !important; }
        .at-style-responsive .at-share-btn { padding: 0 !important; border-radius: 2px !important; }
        .at-resp-share-element .at-share-btn .at-icon-wrapper { width: 26px !important; height: 26px !important; }
        .at-resp-share-element .at-share-btn { margin-bottom: 0 !important; margin-right: 3px !important; }

 
    }


    @media screen and (max-width:1219px){
        .new2_left{ width:605px;}
    }
    
    @media screen and (max-width:999px){
        .new2_left{ width: 545px;}
    }
    
    
    @media screen and (max-width:979px){

        .new2_right .share1{  margin-bottom: 15px;}
        .new2_right { width:100%; float: none; }
        .new2_left{ width:100%; float: none; }
        .new2_right .ex_wrap { max-width: 100%; width: 100%;  }
        .new2_right .ex_wrap dl{ max-width: 90%; width: 90%; } 
    }
    
    @media screen and (max-width:400px){ 
        .new2_right .ex_wrap dl{ width: 90%; } 
        .ex_wrap{ width: 100%;  }
        .ex_wrap dl{ width: 90%; } 
    }

}

// technical
.technical{ padding-bottom: 100px;
    
    // tec_l
    .tec_l{   width: 826px; 
        .te_wrap{ border-top: 1px solid #cccccc; margin-bottom: 50px; } 
        p{ color: #666666; font-size:14px;font-size:1.4rem; line-height: 30px; border-bottom: 1px solid #cccccc;
            padding-left: 26px; background: url(../images/tec_l.png) no-repeat left 19px; font-weight: bold;
        padding-bottom: 10px;  padding-top: 10px;
        a{ color: #666666; }
        }
    }


    // tec_r
    .tec_r{
        width: 321px;  background: #eeeeee;
        .title2{ height: 48px; line-height: 48px; background: $color; color: #fff;
            a{ font-size:22px;font-size:2.2rem; color: #fff; padding-left: 16px; font-weight: bold; text-transform: uppercase; }
        }
    }

    // tec_r_ul
    .tec_r_ul{ max-width: 262px;margin: 0 auto;
        li{ margin-top: 21px; }
        a{ display: block;margin: 0 auto; }
        img{ display: block; margin: 0 auto; border: 1px solid #cccccc; }
        span{ margin-top: 18px; padding-bottom: 8px; display: block; white-space:nowrap; overflow:hidden; text-overflow:ellipsis; text-align: center; font-size:16px;font-size:1.6rem; line-height: 30px;  }

    }



    @media screen and (max-width:1219px) {
        
        // tec_l
        .tec_l{ width: 68%; }
    
        // tec_r
        .tec_r{ width: 30%; }  
    }

    @media screen and (max-width:999px) {
    // tec_l
    .tec_l{ width: 100%; float: none; margin-bottom: 40px; }
    
    // tec_r
    .tec_r{  width: 100%; float: none; }
 
    } 
}

 
  
/*==========sample==================*/
.sample{ padding-bottom: 80px;
    .wp{  background: url(../images/sample.png) no-repeat left top;  }

    .semp_l { width: 670px; margin-left: 81px; padding-top: 34px;  }

    .tile3{ font-size:14px;font-size:1.4rem; line-height: 30px; margin-bottom: 10px; }
    .title4{ font-size:14px;font-size:1.4rem; line-height: 36px;
        span{ display: block; font-weight: bold;  padding-left: 30px; background: url(../images/semp_lli1.png) no-repeat left 15px;  }
    }
  
    .title5{ margin-top: 15px;
        p{ font-size:14px;font-size:1.4rem; line-height: 26px; margin-bottom: 29px;  }
        span{ color: $color;}
    }

    .semp_r{ width: 347px;
        p{  color: $color; text-transform: uppercase; font-size:48px;font-size:4.8rem; font-weight: bold;  line-height: 64px;  }
        span{ color: #333; display: block; padding-left: 85px;  }
    }

    .spam { display: none; margin: 0 auto; }

    .coxin { max-width: 890px; width: 100%; padding-top: 75px; }
    .coxin .int1 { border: 1px solid #d2d2d2; width: 403px; height: 48px; padding-left: 24px; margin-bottom: 21px; float: left; font-size: 14px; font-size: 1.4rem; line-height: 48px; font-family: Verdana; }
    .coxin .int2 { border: 1px solid #d2d2d2; width: 403px; height: 48px; padding-left: 24px; margin-bottom: 21px; float: right; font-size: 14px; font-size: 1.4rem; line-height: 48px; }
    .coxin textarea {font-family: Verdana; border: 1px solid #d2d2d2;  width: 863px; height: 122px; background: url(../images/sapnxing.png) no-repeat left top; padding-left: 24px; margin-bottom: 21px; float: left; font-size: 14px; font-size: 1.4rem; line-height: 48px; }
    .coxin .int3 { border: 1px solid #d2d2d2; width: 221px; height: 48px; padding-left: 24px; margin-bottom: 21px; float: left; font-size: 14px; font-size: 1.4rem; line-height: 48px; }
    .coyanzheng { float: left; margin-bottom: 21px; margin-left: 14px; cursor: pointer; }
    .coxin .int4 { font-family: Verdana; width: 428px; height: 48px;  float: right; margin-bottom: 21px; color: #fff; font-size:14px;font-size:1.4rem; background: $color; cursor: pointer; }
    .coxin .xing { background: url(../images/sapnxing.png) no-repeat left top; }





    @media screen and (max-width: 1219px) { 
        .con_l { width: 56%; }
        .con_r { width: 42%; }
        .coxin .int1 { width: calc( 48% - 24px); }
        .coxin .int2 { width: calc( 48% - 24px); }
        .coxin textarea { width: calc( 100% - 24px); }
        .coyanzheng { margin-left: 11px; } 

        .wp{ background: none;  }
        .semp_l { float: none; max-width: 817px;  width: 100%; margin: 0px auto; padding: 59px;  box-sizing: border-box; background: url(../images/sample.png) no-repeat left top; }
        .semp_r{float: none; max-width: 817px; width: 100%;margin: 0px auto; }
        .spam { display: block; margin: 0 auto;  }　
        .coxin .int4{ width: 281px; }
    }

    @media screen and (max-width: 999px) { 

        .semp_l { float: none; max-width: 817px; background: none; padding: 0px; }
        .con_l { width: 100%; float: none; }
        .con_r { width: 100%; float: none; margin-bottom: 20px; } }

    @media screen and (max-width: 768px) {  

        .semp_r p{ font-size:30px;font-size:3rem; line-height: 36px; }
        .coxin .int1 { width: calc( 100% - 24px); margin-bottom: 10px; }
        .coxin .int2 { width: calc( 100% - 24px); margin-bottom: 10px; }
        .coxin textarea { margin-bottom: 10px; height: 120px; }
        .coxin .int3 { width: calc( 100% - 24px); margin-bottom: 10px; }
        .coyanzheng { margin-left: 0px; margin-bottom: 10px; }
        .coxin .int4 { width: 100%; margin-bottom: 20px; } }





    @media screen and (max-width:1219px) {

    }
    
    @media screen and (max-width:999px) {
    
    }
    
    @media screen and (max-width:768px) {
    
    }
    @media screen and (max-width:320px) {
    
    }  
}
  
